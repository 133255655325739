<template>
  <!-- 询价记录 -->
  <znl-gridmodule ref="flexGrid"
    header=""
    height="100%"
    :loading-type="isShowHeader ? 'animation': 'icon'"
    :tooltip-content="!gridType?'':'橙色的询价记录,表示选中的待查价型号的市场查货结果'"
    :gridtype="isAdopt?'action':'base'"
    :action-btns="actionBtns"
    :has-znl-actions="hasSearchCondition"
    :columns="columns"
    :is-init="true"
    :is-show-rightbtns="false"
    :show-save-btn="false"
    :btn-mouse-enter-menus="btnMouseEnterMenus"
    :is-multi-rows-check="isMultiRowsCheck"
    :show-save-row-right-menu="false"
    :show-delete-row-right-menu="false"
    :show-header="isShowHeader"
    :item-source="itemSource"
    :is-fields-search="isFieldsSearch"
    :search-fields="searchModel"
    :page-size="pageSize"
    :page-index="pageIndex"
    :total-count="totalCount"
    :role="role"
    :users="$store.state.users"
    :on-init="onInit"
    :on-search="onSearch"
    :on-refresh="onRefresh"
    :pagination-position="pagePosition"
    :loading="loading"
    :on-save-row="onSaveRows"
    :on-save-rows="onSaveRows"
    :on-save-setting="onSaveSetting"
    :on-save-config='onSaveConfig'
    :on-reset-setting="onResetSetting"
    :on-page-changed="onBindData"
    :is-activity="isActivity"
    :table-row-class-name="tableRowClassName"
    @switch-to-hide="val=>{$emit('switch-to-hide',val)}"
    checkboxBindingKey="StkInLineID"
    @selection-changed="d=>{this.currentItem=d, $emit('selection-changed',d)}">
    <div slot="heading-slot" v-if='!isShowOperate&&isAdopt' class="v-table-toolbar">
      <znl-button style-type="main" class="btn-purchase-hide" :height='22' tip='请输入采纳量采纳' @click="OnAdoptPrice()">
        <span>按采纳量采纳</span>
      </znl-button>
      <znl-button :height='22' @click="onBindData(1)">
        <i class="iconfont icon-refresh_btn_ic"></i><span>刷新</span>
      </znl-button>
    </div>
    <div>
      <stk-in-edit :title="StkInAddTitle"
        :editvisible="stkInEditVisible"
        v-if="stkInEditVisible"
        :edit-type="editType"
        height="500px"
        @confirm="stkInEditVisible=false"
        @close="stkInEditVisible=false">
      </stk-in-edit>
      <bom-master-detail-edit :title="BomAddTitle"
        :editvisible="bomMasterDetailEditVisible"
        v-if="hasOperationColumn && bomMasterDetailEditVisible"
        :edit-type="editType"
        height="500px"
        @confirm="bomMasterDetailEditVisible=false"
        @close="bomMasterDetailEditVisible=false">
      </bom-master-detail-edit>
      <stk-out-edit :title="StkOutAddTitle"
        :editvisible="stkOutEditVisible"
        v-if="stkOutEditVisible"
        :edit-type="editType"
        height="500px"
        @confirm="stkOutEditVisible=false"
        @close="stkOutEditVisible=false"></stk-out-edit>
      <ord-purchase-order-edit :title="ordEditTitle"
        :editvisible="ordEditVisible"
        v-if="ordEditVisible"
        :edit-type="editType"
        height="500px"
        @confirm="ordEditVisible=false"
        @close="ordEditVisible=false">
      </ord-purchase-order-edit>

    <!-- 新增客户需求 -->
    <znl-bom-master-edit
        title="新增客户需求"
        :editvisible="bomMasterDetailEditVisibleTwo"
        v-if="bomMasterDetailEditVisibleTwo"
        :adddata="bomMasterDetail"
        edit-type="addNew"
        @confirm="bomMasterDetailEditVisibleTwo=false"
        @close="bomMasterDetailEditVisibleTwo=false, onSearch()">
    </znl-bom-master-edit>
    </div>
  </znl-gridmodule>

</template>
<script>
import { mixin as getCommonDataMixin } from '~assets/scripts/methods/getCommonData'
const StkOutEdit = () => import('@c_modules/Stk/StkOutEdit' /* webpackChunkName: "pages/stk" */)
const StkInEdit = () => import('@c_modules/Stk/StkInEdit' /* webpackChunkName: "pages/stk" */)
import OrdPurchaseOrderEdit from '@c_modules/Purchase/OrderEdit'
import ZnlBomMasterEdit from '@c_modules/BOM/BomMasterEdit'
import { mixin as znlMethodsMinix } from '@c_common/znlGrid/scripts/znlMethods'
import { openLinkAll } from '@scripts/methods/common'
import { renderRequiredColumn } from '~assets/scripts/methods/common'

const CONFIG = {
  configURL: 'StkInquireRecord/GetConfig',
  saveConfigURL: 'StkInquireRecord/SaveConfig',
  resetConfigURL: 'StkInquireRecord/ResetConfig',
  searchURL: 'StkInquireRecord/Search',
  StockVMIAdd: 'StockVMI/AddList',
  saveURL: 'InquireAdopt/SaveByAdoptQty', // 按采纳量采纳
  saveSingleURL: 'InquireAdopt/SaveByAdoptInfo'  // 按单条询价采纳
}

export default {
  name: 'StkInquireRecord',
  mixins: [znlMethodsMinix, getCommonDataMixin, openLinkAll, renderRequiredColumn], // 改变也可以刷新页面
  // mixins: [znlMethodsMixin, znlMethodswatch], // 第二个方法需要添加后才能监控到外部条件改变也可以刷新页面
  config: CONFIG,
  components: {
    // ZnlModule,
    StkInEdit,
    StkOutEdit,
    OrdPurchaseOrderEdit,
    ZnlBomMasterEdit
    // Icon
  },
  data () {
    return {
      editType: 'edit',
      StkOutAddTitle: '新增发货单',
      StkInAddTitle: '新增入库单',
      BomAddTitle: '新增客户需求',
      ordEditVisible: false,
      resourceList: [],
      stkOutEditVisible: false,
      stkInEditVisible: false,
      bomMasterDetailEditVisible: false,
      ordEditTitle: '新增采购单',
      actionBtns: [],
      columns: [],
      itemSource: [],
      editRowIndex: -1,
      searchFields: {},
      filterFields: {},
      summaryColumns: [],
      Size: 30,
      pageIndex: 1,
      totalCount: 0,
      role: '1',
      OrdPurchaseOrderAddVisible: false,
      isShow: false,
      IsRefreshByInquire: false,
      InquireModel: '',
      RFQ_InquireModel: [],
      btnMouseEnterMenus: {
        List: []
      },
      Status: true,
      inputModel: '', // 需要查询的型号
      IQGUID: null,   // 辅助字段
      loading: false,
      // 需采纳量
      needAdoptQty: 0,
      IsSingle: false,
      bomMasterDetailEditVisibleTwo: false,
      bomMasterDetail: [],
      // stkInquiry: {},
      stkInquiry: {// 询价参数传递
        SupplierName: '', // 供应商名称
        Model: '', // Model
        Brand: '',
        Packaging: '',
        MakeYear: '',
        Quality: '',
        DeliveryDate: '',
        InvQty: 0,
        Qty: 0,
        MPQ: '',
        Price: 0
      }
    }
  },
  computed: {
    pageSize: {
      get () {
        return this.Size
      },
      set (val) {
        this.Size = val
      }
    }
  },
  props: {
    bomQuoteType: {
      type: String,
      default: ''
    },
    purGrossRate: {
      type: Number,
      default: 0
    },
    initData: {
      type: Boolean,
      default: false
    },
    isActivity: {
      type: Boolean,
      default: true
    },
    isAdopt: {
      type: Boolean,
      default: false
    },
    pagePosition: {
      type: String,
      default: 'bottom'
    },
    header: {
      type: String,
      default: '询价记录'
    },
    searchModel: {
      type: Object,
      default: () => {
        return {}
      }
    },
    isFieldsSearch: {
      type: Boolean,
      default: true
    },
    height: {
      type: String,
      default: 'auto'
    },
    isShowShoppingCart: {
      type: Boolean,
      default: false
    },
    isShowSelect: {
      type: Boolean,
      default: false
    },
    hasOperationColumn: {
      type: Boolean,
      default: true
    },
    isShowOperate: {
      type: Boolean,
      default: false
    },
    isShowHeader: {
      type: Boolean,
      default: false
    },
    hasSearchCondition: { type: Boolean, default: false },
    isMultiRowsCheck: { type: Boolean, default: false },
    gridType: { type: Boolean, default: false },
    // 来源类型：1.需求明细 2.询价通知明细 3.采购通知明细
    sourceType: {
      type: Number
    },
    // 需求来源GUID
    sourceGUID: {
      type: String
    }
  },
  watch: {
    searchModel: {
      handler (val) {
      },
      deep: true
    }
  },

  methods: {
    async onInit () {
      this.loading = true
      let config = await this.isSaveStorage('inquireLine', CONFIG.configURL)
      // 获取模块打开或者关闭的状态
      this.GetStatus(config.Status)
      this.resourceList = config.ResourceList
      let columns = this.flexGridColumnsHandler(config.ColumnConfigs)
      // 列的初始化
      this.settingColumns(columns)
      // 操作按钮初始化
      this.settingActionBtn(columns)
      this.setDateColumnFormat(columns)
      this.defaultConfig = config
      this.resourceList = config.ResourceList
      this.columns = columns
      this.role = config.Role
      this.pageSize = config.PageSize > 30 ? this.pageSize : config.PageSize
      this.fieldsFilter = {}
      this.fieldsFilter = _.extend({}, this.fieldsFilter, this.searchModel)
      if (this.initData) {
        this.onBindData(1)
      } else {
        this.loading = false
        this.$emit('page-loading', false)
      }
    },
    // 列的初始化
    settingColumns (columns) {
      // 隐藏报价采纳数量
      _.each(columns, (item, index) => {
        if (item.binding === 'QuoteAdoptQty' && this.sourceType !== 3) {
          item.hasRole = false
          item.visibled = false
        }
      })

      if (this.isAdopt) {
        columns = this.renderRequiredColumn(columns)
      }
      _.each(columns, (column, index) => {
        if (column.binding === 'Model' ||
          column.binding === 'CustomerName' ||
          column.binding === 'BillNo') {
          _.extend(column,
            {
              className: 'znl-linkAll-column',
              renderCell: this.openLinkAll(column)
            })
        }
      })

      // 查询人
      let UserAllowUsers = _.map(this.selectOptionsAllUserURL, function (obj, index) {
        return { key: obj.value, value: obj.value }
      })
      _.extend(
        _.find(columns, function (column) {
          return column.binding === 'CreatedName'
        }),
        { dataMap: UserAllowUsers, showDropDown: true }
      )

      // 币种
      let CurrencyNames = _.map(this.selectOptionsCurrencyCode, function (obj, index) {
        return { key: obj.value, value: obj.value }
      })
      _.extend(
        _.find(columns, function (column) {
          return column.binding === 'CurrencyName'
        }),
        { dataMap: CurrencyNames, showDropDown: true }
      )

      let AdoptQtyColumns = _.find(columns, column => column.name === 'AdoptQty')
      if (!this.isShowOperate && this.isAdopt) {
        _.extend(AdoptQtyColumns, { visible: true, hasRole: true })
      } else {
        _.extend(AdoptQtyColumns, { visible: false, hasRole: false })
      }
      // 供应商报价权限控制Price
      let Jurisdiction = !this.getSpecialResourceByCode('ViewBuyPrice')

      // 处理特殊权限  不看供应商资料(供应商、联系人、电话、手机、地址)
      let noSeeSupplier = this.getSpecialResourceByCode('NoSeeSupplier')
      let hideCols = ',SupplierName,Contact,Telephone,Email,Address,QQ,Mobile,'

      _.each(columns, (item, index) => {
        if (Jurisdiction && item.binding === 'Price') {
          item.hasRole = false
        }
        if (noSeeSupplier && hideCols.indexOf(item.binding) > 0) {
          item.hasRole = false
        }
      })
    },
    // 操作按钮初始化
    settingActionBtn (columns) {
      !this.isShowOperate && this.hasOperationColumn && columns.unshift({
        'name': 'Ordpurchase',
        'dataType': 5,
        'binding': 'Ordpurchase',
        'width': 80,
        'header': '生成采购单',
        'isFixed': false,
        'visible': true,
        'allowSorting': false,
        'isFieldsSearch': false,
        'isSystem': true,
        'buttons': [
          {
            'content': '生成采购单',
            'className': 'znl-table-btn-success',
            // 'when': this.hasRes('Delete'),
            'click': (row) => {
              this.onOrdpurchase(row)
            }
          }
        ]
      })
      if (this.isShowSelect) {
        this.actionBtns = [
          {
            name: '给客户报价',
            isShow: this.hasRes('onBomAdd'),
            iconName: 'icon-quoted-price_btn_ic',
            click: () => { this.onBomAddRows() }
          },
          {
            name: '销售出库',
            isShow: this.hasRes('onAddStkOut'),
            iconName: 'icon-sell-wh_btn_ic',
            click: () => { this.stkOutAddRows() }
          },
          {
            name: '采购入库',
            isShow: this.hasRes('onStkInadd'),
            iconName: 'icon-buy-wh_btn_ic',
            click: () => { this.stkInAddRows() }
          },
          {
            name: '添加到供应商库存',
            isShow: this.hasRes('stockVMIAdd'),
            iconName: 'icon-add_btn_ic',
            click: () => { this.stockVMIAddList() }
          }
        ]

        this.isShow = true
      }
      this.btnMouseEnterMenus = {
        List: [
          {
            name: '给客户报价',
            isShow: this.hasRes('onBomAdd'),
            click: row => {
              this.onBomAdd(row)
            }
          },
          {
            name: '销售出货',
            isShow: this.hasRes('onAddStkOut'),
            click: row => {
              this.onAddStkOut(row)
            }
          },
          {
            name: '采购入库',
            isShow: this.hasRes('onStkInadd'),
            click: row => {
              this.onStkInadd(row)
            }
          }
        ],
        Binding: 'operate'
      }
      !this.isShowOperate && this.isAdopt && columns.unshift({
        name: 'adopt',
        dataType: 5,
        binding: 'adopt',
        width: 48,
        header: '采纳',
        visible: true,
        allowSorting: false,
        isReadOnly: false,
        editable: false,
        isFieldSearch: true,
        isSystem: true,
        buttons: [
          {
            className: 'znl-table-btn',
            content: d => {
              if (this.sourceType === 1) {   // 配单界面
                if (d.BDLineGUID === this.searchModel.BDLineGUID) {
                  return '<a>已采纳</a>'
                } else {
                  return '<a>采纳</a>'
                }
              } else if (this.sourceType === 2) {  // 待查价界面
                if (d.ASGUID === this.searchModel.ASGUID) {
                  return '<a>已采纳</a>'
                } else {
                  return '<a>采纳</a>'
                }
              } else if (this.sourceType === 3) {  // 待采购界面
                if (d.PNGUID === this.searchModel.PNGUID) {
                  return '<a>已采纳</a>'
                } else {
                  return '<a>采纳</a>'
                }
              }
            },
            click: (row) => {
              let rows = []
              // if (row.Qty >= this.searchModel.NeedAdoptQty) {
              //   row.AdoptQty = this.searchModel.NeedAdoptQty
              // } else {
              //   row.AdoptQty = row.Qty
              // }
              this.IsSingle = true
              row.IsSingle = true
              rows.push(row)
              this.onSaveRows(rows)
            }
          }
        ]
      })
      this.isShowOperate && this.hasRes('stockVMIAdd') ? columns.unshift({
        name: 'addToVMInquire',
        dataType: 5,
        binding: 'addToVMInquire',
        width: 110,
        header: '操作',
        visible: true,
        allowSorting: false,
        isFieldsSearch: false,
        isSystem: true,
        isReadOnly: true,
        isFixed: false,
        buttons: [
          {
            content: '',
            className: 'znl-table-btn-success',
            when: true,
            click: (row) => {
              let rows = []
              rows.push(row)
              this.stockVMIAddList(row)
            }
          }
        ]
      }) : ''
      this.isShowOperate && this.hasRes('onAddStkOut') ? columns.unshift({
        name: 'quote',
        dataType: 5,
        binding: 'quote',
        width: 30,
        header: '报',
        visible: true,
        allowSorting: false,
        isFieldsSearch: false,
        isSystem: true,
        isReadOnly: true,
        isFixed: false,
        buttons: [
          {
            content: '报',
            className: 'znl-table-btn-success',
            when: true,
            click: (row) => {
              let rows = []
              rows.push(row)
              this.onQuotedPrice(rows)
            }
          }
        ]
      }) : ''
      this.isShowOperate && this.hasRes('onAddStkOut') && !this.onlySalesPurchaseOrder ? columns.unshift({
        name: 'stkIn',
        dataType: 5,
        binding: 'stkIn',
        width: 30,
        header: '入',
        visible: true,
        allowSorting: false,
        isFieldsSearch: false,
        isSystem: true,
        isReadOnly: true,
        isFixed: false,
        buttons: [
          {
            content: '入',
            className: 'znl-table-btn-success',
            when: true,
            click: (row) => {
              this.onStkInadd(row)
            }
          }
        ]
      }) : ''
      this.isShowOperate && this.hasRes('onAddStkOut') && !this.onlySalesPurchaseOrder? columns.unshift({
        name: 'stkOut',
        dataType: 5,
        binding: 'stkOut',
        width: 30,
        header: '出',
        visible: true,
        allowSorting: false,
        isFieldsSearch: false,
        isSystem: true,
        isReadOnly: true,
        isFixed: false,
        buttons: [
          {
            content: '出',
            className: 'znl-table-btn-success',
            when: true,
            click: (row) => {
              this.onAddStkOut(row)
            }
          }
        ]
      }) : ''
      this.isShowSelect && columns.unshift({// 操作列
        name: 'operate',
        dataType: 5,
        binding: 'operate',
        width: 36,
        header: '操作',
        visible: true,
        isReadOnly: true,
        isFieldsSearch: false,
        isSystem: true,
        align: 'left',
        className: 'znlerp-operate znl-text-center',
        buttons: [
          {
            content: '<i class="iconfont icon-left_nav_show_btn"></i>',
            className: 'znl-table-btn',
            click: () => { }
          }
        ]
      })
    },
    setDateColumnFormat (columns) {
      var that = this
      const columnAddVMI = columns.find((item) => { return item.binding === 'addToVMInquire' })
      if (!columnAddVMI) {
        return
      }
      columnAddVMI.renderCell = (h, rowObj) => {
        var content = ''
        if (!rowObj.row.IsAddToVM) {
          content = '<button type="button" class="el-button el-button--default el-button--mini eleBtnStyle znl-table-btn-success">添加到供应商库存</button>'
        }
        return h('div', {
          domProps: {
            innerHTML: content
          },
          on: {
            click () {
              that.stockVMIAddList(rowObj.row)
            }
          }
        })
      }
      // 供应商单元格 点击打开 ‘记录供应商报价’
      const columnSupplierName = columns.find((item) => { return item.binding === 'SupplierName' })
      if (!columnSupplierName) {
        return
      }
      columnSupplierName.renderCell = (h, rowObj) => {
        let content = ''
        if (rowObj.row.SupplierName) {
          content = '<button type="button" class="el-button el-button--default el-button--mini eleBtnStyle znl-table-btn-success">' + rowObj.row.SupplierName + '</button>'
        }
        return h('div', {
          domProps: {
            innerHTML: content
          },
          on: {
            click () {
              that.onInquiry(rowObj.row)
            }
          }
        })
      }
    },
    onInquiry: async function (data) { // 询价
      this.$nextTick(function () {
        this.stkInquiry.SupplierName = data.SupplierName
        this.stkInquiry.Model = data.Model
        this.stkInquiry.Brand = data.Brand
        this.stkInquiry.Packaging = data.Packaging
        this.stkInquiry.MakeYear = data.MakeYear
        this.stkInquiry.Quality = data.Quality
        this.stkInquiry.InvQty = data.InvQty
        this.stkInquiry.MPQ = data.MPQ
        this.stkInquiry.Qty = data.InvQty
        this.stkInquiry.Price = data.BuyPrice
        this.addInquire(this.stkInquiry, function (msg) {
          if (msg === '不能向自己询价') {
            this.$message({ message: msg, type: 'warning' })
          }
        })
      })
    },
    hasRes (code) {
      return _.some(this.resourceList, page => page.Code === code)
    },
    onAdoptEnd (stkGUID) { // 采纳结束后前台显示值改变
      // console.log(stkGUID)
      this.searchModel.IQGUID = stkGUID
    },
    onQuotedPrice (rows) {
      if (rows !== null && rows.length > 0) {
        this.bomMasterDetailEditVisibleTwo = true
        this.$nextTick(() => {
          this.bomMasterDetail = rows
        })
      } else {
        this.$emit('QuotePriceDetial', rows)
      }
    },
    onOrdpurchase (inquire) { // 生成采购单
      this.$store.state.visibled = false
      // console.log(inquire, 'inquire')
      let paramOrder = { InquireModel: inquire }
      this.$store.commit('setParamOrder', paramOrder)
      this.$nextTick(function () {
        this.$refs.flexGrid.clearSelection()
        this.editType = 'Edit'
        this.ordEditTitle = '新增采购单'
        this.ordEditVisible = true
      })
      // this.OrdPurchaseOrderAddVisible = true
      // this.IsRefreshByInquire = !this.IsRefreshByInquire
    },
    onAddStkOut (rows) {
      let order = { RFQ_InquireModel: [rows] }
      this.$store.commit('setParamOrder', order)
      this.$nextTick(function () {
        this.$refs.flexGrid.clearSelection()
        this.stkOutEditVisible = true // 显示编辑页面
      })
    },
    onStkInadd (rows) { // 采购入库传值方法
      // console.log(rows, 'rows')
      let order = { RFQ_InquireModel: [rows] }
      this.$store.commit('setParamOrder', order)
      // console.log(order, 'order')
      this.$nextTick(function () {
        this.stkInEditVisible = true // 显示编辑页面
      })
    },
    onBomAdd (rows) {
      this.$emit('QuotePriceDetial', rows)
    },
    StockVMIAdd (rows) {
      return this.$post(CONFIG.StockVMIAdd, rows, (data) => {
        if (data === undefined || data === '') {
          this.$message({ message: '保存失败', type: 'error' })
        } else {
          this.$message({ message: '保存成功', type: 'success' })
          this.onBindData(this.pageIndex)
        } this.$refs.flexGrid.clearSelection()
      })
    },
    stkInAddRows () { // 批量采购入库
      let rows = this.onSelectRow() // 选中行的bdlineguid数组
      if (rows.length > 0) {
        let order = { InquireModel: rows }
        this.$store.commit('setParamOrder', order)
        this.$nextTick(function () {
          this.$refs.flexGrid.clearSelection()
          this.stkInEditVisible = true // 显示编辑页面
        })
      }
    },
    stkOutAddRows () { // 批量销售出库
      let rows = this.onSelectRow() // 选中行的bdlineguid数组
      if (rows.length > 0) {
        let order = { InquireModel: rows }
        this.$store.commit('setParamOrder', order)
        this.$store.state.visibled = false
        this.$nextTick(function () {
          this.$refs.flexGrid.clearSelection()
          this.stkOutEditVisible = true
        })
      }
    },
    onBomAddRows () { // 批量报价
      let rows = this.onSelectRow() // 选中行的bdlineguid数组
      if (rows.length > 0) {
        let order = { InquireModel: rows }
        this.$store.commit('setParamOrder', order)
        this.$nextTick(function () {
          this.$refs.flexGrid.clearSelection()
          this.bomMasterDetailEditVisible = true // 显示编辑页面
        })
      }
    },
    stockVMIAddList (row = null) { // 批量添加
      let rows = []
      if (row) {
        rows = []
        rows.push(row)
      } else {
        rows = this.$refs.flexGrid.getSelectedRows()
      }
      if (_.isUndefined(rows[0])) {
        if (!this.CurrentItem || this.CurrentItem.BDLineGUID) {
          // 选中行
          return this.$message({ message: '请至少选择一条数据进行操作', type: 'warning' })
        } else {
          this.$refs.flexGrid.clearSelection()
        }
      }
      if (rows.length > 0) {
        var isDiff = true
        _.each(rows, r => {
          if (!r.IsAddToVM) {
            isDiff = false
          }
        })
        if (isDiff) {
          return this.$message({ message: '该型号已经添加到供应商库存', type: 'warning' })
        } else {
          this.StockVMIAdd(rows)
        }
      }
      // row['AddToVMInquire']
    },
    onSelectRow () {
      // let guid = [] // 选中行的bdlineguid数组
      let rows = this.$refs.flexGrid.getSelectedRows()
      if (_.isUndefined(rows[0])) {
        if (!this.CurrentItem) {
          // 选中行
          return this.$message({ message: '请至少选择一条数据进行操作', type: 'warning' })
        } else {
          this.$refs.flexGrid.clearSelection()
        }
      } else {
        // 判断选中行的客户是否一致
        var lastCustomerName = rows[0].SupplierName
        var isDiff = false
        _.each(rows, r => {
          if (lastCustomerName !== r.SupplierName) {
            isDiff = true
          }
        })
        if (isDiff) {
          return this.$message({ message: '请选择相同供应商的数据进行操作', type: 'warning' })
        }
        // guid = _.map(rows, item => {
        //   return item.BDLineGUID
        // })
      }
      return rows
    },
    onBindData (pageIndex = 1) {
      this.loading = true

      let guid = null
      if (this.sourceType === 1) {
        guid = this.searchModel.BDLineGUID
      } else if (this.sourceType === 2) {
        guid = this.searchModel.ASGUID
      } else {
        guid = this.searchModel.PNGUID
      }
      let data = _.extend({}, {

        FieldWhereString: JSON.stringify(this.fieldsFilter),
        PageIndex: pageIndex,
        PageSize: this.pageSize
      }, {
          // 外部搜索条件
        Model: this.inputModel,
        IsAnalyzer: false,
        SourceType: this.sourceType,
          // 需求来源GUID  1.需求明细 2.询价通知明细 3.采购通知明细
        SourceGUID: guid
      })
      if (!this.hasSearchCondition) {
        data.Model = this.searchModel.Model
      }

      return this.$post(CONFIG.searchURL, data, (data) => {
        this.pageIndex = pageIndex
        _.each(data.ResultList, item => {
          // 给AdoptQty赋初始值 避免采纳时获取不到采纳数量 。
          if (item.AdoptQty === undefined) {
            item.AdoptQty = null
          }
        })
        this.itemSource = data.ResultList
        this.totalCount = data.TotalCount
      }).finally(() => {
        this.loading = false
        this.$emit('page-loading', false)
        this.$emit('grid-show', true)
      })
    },
    dateFormat (value) {
      // console.log(value)
      if (value === '') {
        return value
      }
      try {
        let date = new Date(Date.parse(value))
        if (date) {
          let month = (date.getMonth() + 1).toString()
          let day = date.getDate().toString()
          if (month.length === 1) { month = '0' + month }
          if (day.length === 1) { day = '0' + day }
          return date.getFullYear().toString() + '-' + month + '-' + day
        }
      } catch (error) {
        return value
      }
    },
    async onHeadSearch (params) {
      this.fieldsFilter.Model = params.Model
      this.searchModel.BDLineGUID = params.BDLineGUID
      await this.onBindData(1)
    },
    onSearch (params) {
      // // debugger
      // 要设置为null,原因:转成 FieldWhereString 里面会带上 ASGUID,BDLineGUID的值
      // 防止后台拿到这两个GUID的值 作为查询条件
      // params.ASGUID = null
      // params.BDLineGUID = null
      // console.log('params', params)
      this.IQGUID = params.IQGUID || null
      this.fieldsFilter = {}
      this.fieldsFilter = _.extend({}, this.fieldsFilter, params)
      return this.onBindData(1)
    },
    hasValue (val) {
      if (val === null || val === undefined || val === '') {
        return false
      }
      return true
    },
    async  OnAdoptPrice () {
      let rows = this.$refs.flexGrid.getChangedData()
      this.IsSingle = false
      this.onSaveRows(rows)
    },
    async onSaveRows (rows) {
      let isDiff = false
      let msg = ''
      let diffMode = ''
      _.each(rows, item => {
        if (item.AdoptQty < 0) {
          msg = '采纳数量不能小于0'
          return
        } else {
          msg = ''
        }
        if (item.Model !== this.searchModel.Model) {
          diffMode += item.Model + ','
          isDiff = true
        }
      })
      if (this.hasValue(msg)) {
        return this.$message({ message: msg, type: 'warning' })
      }
      let text = '采纳型号' + diffMode + '与需求型号' + this.searchModel.Model + '不一致，是否继续采纳?'
      if (isDiff) {
        await this.$confirm(text, '采纳提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning' }
        ).then(() => {
          return this.onSaveAdopt(rows)
        })
      } else {
        return await this.onSaveAdopt(rows)
      }
    },
    onSaveAdopt (rows) {
      let info = _.find(rows, item => {
        return !this.hasValue(item.Price) || item.Price === 0
      })
      if (info) {
        this.$message({ message: '型号:' + info.Model + ' 供应商报价单价不能为空', type: 'warning' })
        return
      }
      if (this.IsSingle) { // 单条采纳
        // let rows = this.$refs.flexGrid.getChangedData()
        if (rows[0].Qty >= this.searchModel.NeedAdoptQty) {
          rows[0].AdoptQty = this.searchModel.NeedAdoptQty
        } else {
          rows[0].AdoptQty = rows[0].Qty || this.searchModel.NeedAdoptQty
        }
      }
      info = _.find(rows, item => {
        return !this.hasValue(item.AdoptQty) || item.AdoptQty <= 0
      })
      if (info) {
        return this.$message({ message: '型号:' + info.Model + ' 采纳量不能为空', type: 'warning' })
      }
      _.each(rows, item => {
        item.BDLineGUID = this.searchModel.BDLineGUID
        item.ASGUID = this.searchModel.ASGUID
        item.PNGUID = this.searchModel.PNGUID
      })
      if (this.IsSingle) {
        this.onSaveBySingle(rows[0])
      } else {
        this.onSaveByAdoptQty(rows)
      }
    },
    // 按采纳量采纳
    async onSaveByAdoptQty (rows) {
      if (rows.length > 0) {
        let array = []
        _.each(rows, item => {
          let info = {
            CurrencyCode: item.CurrencyCode,
            Qty: item.AdoptQty,
            Price: item.Price,
            IAGUID: item.IAGUID,
            IQGUID: item.IQGUID,
            PNGUID: item.PNGUID,
            Packaging: item.Packaging,
            MPQ: item.MPQ,
            MakeYear: item.MakeYear,
            Quality: item.Quality,
            DeliveryDate: item.DeliveryDate,
            ASGUID: item.ASGUID || null,
            BDLineGUID: item.BDLineGUID,
            Model: item.Model,
            Brand: item.Brand,
            TaxRate: item.TaxRate,
            BomQuoteType: this.bomQuoteType,
            PurGrossRate: this.purGrossRate,
            IsSingle: item.IsSingle
          }
          array.push(info)
        })
        this.loading = true
        return await this.$post(CONFIG.saveURL, array, (datas, logic) => {
          if (logic.code === 200) {
            this.$emit('save-success', datas)
            this.$message({ message: '保存成功', type: 'success' })
          } else {
            this.$message({ message: logic.msg, type: 'error' })
          }
          this.onRefresh()
        }).finally(() => {
          this.loading = false
        })
      }
    },
    // 按单条询价
    async onSaveBySingle (row) {
      let info = {
        CurrencyCode: row.CurrencyCode,
        Qty: row.AdoptQty,
        Price: row.Price,
        IQGUID: row.IQGUID,
        PNGUID: row.PNGUID,
        Packaging: row.Packaging,
        MPQ: row.MPQ,
        MakeYear: row.MakeYear,
        Quality: row.Quality,
        DeliveryDate: row.DeliveryDate,
        ASGUID: row.ASGUID || null,
        BDLineGUID: row.BDLineGUID,
        Model: row.Model,
        Brand: row.Brand,
        TaxRate: row.TaxRate,
        BomQuoteType: this.bomQuoteType,
        PurGrossRate: this.purGrossRate,
        IsSingle: true
      }

      this.loading = true
      return await this.$post(CONFIG.saveSingleURL, info, (datas, logic) => {
        if (logic.code === 200) {
          this.$emit('save-success', datas)
          this.$message({ message: '保存成功', type: 'success' })
        } else {
          this.$message({ message: logic.msg, type: 'error' })
        }
        this.onRefresh()
      }).finally(() => {
        this.loading = false
      })
    },
    async onSaveSetting (columns, pageSize) {
      localStorage.removeItem('inquireLine')
      await this.$post(this.$options.config.saveConfigURL, this.flexGridSettingHandler(columns, pageSize, this.defaultConfig))
    },
    // 右键点击表头设置列
    onSaveConfig (column) {
      localStorage.removeItem('inquireLine')
      return this.$post(this.$options.config.saveConfigURL, this.flexGridSettingHandler([column], this.pageSize, this.defaultConfig))
    },
    async onRefresh () {
      await this.onBindData(this.pageIndex)
    },
    async onResetSetting () {
      localStorage.removeItem('inquireLine')
      await this.$post(this.$options.config.resetConfigURL)
    },
    // 获取模块状态
    // 获取模块状态
    GetStatus (val) {
      if (this.isActivity) {
        this.Status = val !== 0
        this.Status ? this.$refs.flexGrid.toggleTableShow(true) : this.$refs.flexGrid.toggleTableShow(false)
      } else {
        this.$refs.flexGrid.toggleTableShow(true)
      }
    },
    // 保存模块状态
    saveStatus (val) {
      let Status = val ? 1 : 0
      let defaultConfig = this.defaultConfig
      if (defaultConfig !== undefined && Status !== this.defaultConfig.Status) {
        this.defaultConfig.Status = Status
        let statusConfig = this.defaultConfig
        statusConfig.ColumnConfigs = []
        this.$post(this.$options.config.saveConfigURL, statusConfig)
      }
    },
    tableRowClassName (obj) {
      // if (obj.row.IQGUID === this.IQGUID) {
      //   return 'currentInquireClass'
      // } else {
      //   return ''
      // }
    }
  },
  mounted () {
    this.$refs.flexGrid.toggleTableShow(false)
  }
}
</script>

<style lang="scss">
.floatright {
  float: right;
}
.currentInquireClass {
  .znl-tablecell {
    color: red;
  }
}
</style>
