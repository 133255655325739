import {
  getCookie,
  setCookie,
  delCookie,
  addClass,
  setStyle,
  getCookie2,
  setCookie2
} from '../utils/dom'

import {
  AUTHURL,
  AYTHNEWURL,
  AUTHUPATEURL,
  ERPCOOKIENAME,
  LINKALLCOOKIENANE,
  ErpCookieMinutes
} from '@scripts/config'
import client from './client'
import {
  getSessionId
} from '~/lib/auth'
import * as urls from '~/lib/urls'

import {
  isClient
} from '~/lib/runtime'
import {
  getErpAuthInfo
} from '~/lib/native'

const debug = process.env.NODE_ENV !== 'production'

const apiDomain = urls.getErpApiBaseUrl()
const bomaiDomain = urls.getBomaiApiBaseUrl()
const isGetTokenFromClient = true // 是否从客户端直接获取token
const debugInfo = {
  openDebug: false, // 是否开启调试正式环境模式。开启之后将会用 下面的 accessToken去请求 apiDomain. 该模式只在 debug = true的时候生效
  // accessToken: "7f3WvukwpMOJK0vmaRmZjAf18ACl/XONrSfDdHKz7fK4rjmvMqM6nfAi6Z0vXt7VsvsquspoQpvgu8ljx+9SCA==",
  apiDomain: "http://test.znlbd.com/api/"
}

/* 在初始登录成功的情况下的 登录状态过期才会弹出 账号验证失败, 请重新登陆 */
let globalInitialLoginSuccess = false; // 是否初始登录成功

export default {
  mixins: [client],
  data() {
    return {
      loginning: false
    }
  },
  computed: {
    BomAIApiDomain() {
      return bomaiDomain
    },
    ErpApiDomain() {
      return apiDomain
    },
    loadingTextForLoading() {
      return '正在加载数据，请稍后...'
    },
    loadingTextForSave() {
      return '正在保存数据，请稍后...'
    },
    getErpTokenID() {
      return getCookie(ERPCOOKIENAME, this.$store)
    },
    copyright() {
      return new Date().getFullYear()
    }
  },
  methods: {
    log(...args) {
      debug && !_.isUndefined(console) && console.log.apply(console, args)
    },
    openTab(name, query) {
      this.$router.push({
        name: name,
        query: query
      })
    },
    async userLogin(isRefresh = false) {
      // localStorage.clear()
      this.onClearStorage()
      this.$store.commit('setPageLoading', true)
      let client = getCookie("Client") || "Windows"
      let cookie = ""
      let validateCurrentToken = false
      let authFromServer

      if (client === "mac") {
        console.log('user login from mac. get cookie.')
        validateCurrentToken = true
        cookie = getCookie(ERPCOOKIENAME, this.$store)
      } else if (isGetTokenFromClient && isClient()) {
        // 如果是嵌套在客户端里面，则直接从客户端获取token, 不再向接口请求token
        authFromServer = await getErpAuthInfo(isRefresh)
        console.log('in client.', authFromServer)

        // 如果客户端获取失败，则从接口请求
        if (authFromServer === null || authFromServer === undefined) {
          console.log('get erp auth info failed')
          validateCurrentToken = false
          cookie = await getSessionId()
          delCookie(ERPCOOKIENAME)
        }
      } else {
        console.log('user login from windows. get cookie.')
        validateCurrentToken = false
        cookie = await getSessionId()
        delCookie(ERPCOOKIENAME)
        delCookie('token')
      }

      if (debug && debugInfo.openDebug && debugInfo.accessToken) {
        authFromServer = {
          AccessionToken: debugInfo.accessToken
        }
      }

      let authed = false
      if (authFromServer && authFromServer.AccessionToken) {
        authed = true
      } else {
        if (cookie) {
          if (validateCurrentToken) {
            authFromServer = await this.$post(AUTHURL)
            console.log('is validate current token', authFromServer)
          } else {
            let tkieName = 'ErpToken ' + cookie
            authFromServer = await this.$postBytkie(tkieName, AYTHNEWURL)
            console.log('is token', authFromServer)
          }
        }
      }

      if (!_.isUndefined(authFromServer)) {
        authed = true
        await this.setCookieAndAccountInfo(authFromServer)
      }

      if (authed) {
        await this.clearCurrentUserPermission()
        await this.getPageRoles()
        this.$store.commit('setPageLoading', false)
        return Promise.resolve(authFromServer)
      } else {
        this.$store.commit('setPageLoading', false)
        return Promise.reject()
      }
    },

    async initLogin(sessionObj) {
      this.onClearStorage()

      this.$store.commit('setPageLoading', true)
      await this.setCookieAndAccountInfo(sessionObj)
      await this.clearCurrentUserPermission()
      await this.getPageRoles()
      this.$store.commit('setPageLoading', false)
      return Promise.resolve(sessionObj)
    },

    async checkLogin() {
      let token = getCookie2('login_token');
      if (token && token !== 'ErpApiundefined') {
        // 检查token是否已失效
        try {
          const info = await this.$postBytkie(token, 'Security/GetLoginInfo')
          await this.initLogin(info)
          await this.$store.commit('setUseInfo', info)
          let tokenBuk = getCookie2('login_token');
          if (!tokenBuk || tokenBuk === 'null') {
            return false;
          }
          return true
        } catch (error) {

          return false;
        }
      }
      return false;
    },

    async setCookieAndAccountInfo(authFromServer) {
      this.$store.commit('setAccountInfo', authFromServer)
      delCookie(ERPCOOKIENAME)

      const token = authFromServer.AccessionToken ? authFromServer.AccessionToken : null
      const jwtinfo = {
        name: ERPCOOKIENAME,
        value: token ? 'ErpApi' + token : null
      }
      setCookie(jwtinfo, this.$store)
      setCookie2('login_token', ErpCookieMinutes, jwtinfo.value)

      const jwtinfo_linkall = {
        name: LINKALLCOOKIENANE,
        value: token ? 'JWT' + token : null
      }
      setCookie(jwtinfo_linkall, this.$store)
      this.$store.commit('setPageLoading', true)
    },

    // 更新令牌
    async updateClientSessionid() {
      if (!!this.loginning) {
        return
      }
      this.loginning = true
      this.$store.commit('setPageLoading', true)
      this.userLogin(true).then(() => {
        window.__ZNLShowReloginAlert = false
        this.$store.commit('setPageLoading', false)
        this.$message({
          message: '重新登录成功!',
          type: 'success'
        })
      })
    },

    //清除当前用户的权限缓存
    async clearCurrentUserPermission() {
      this.$post("UserResource/ClearPermission")
    },

    // 获取页面限项
    async getPageRoles() {
      // if (CONFIG.debug) {
      this.$store.commit('setAccountInfo', {
        pageAuthorUrl: 'UserResource/GetPageModuls',
        getPageConfigUrl: 'UserResource/GetPageConfig',
        savePageConfigUrl: 'UserResource/SavePageConfig',
        resetPageConfigUrl: 'UserResource/ResetPageConfig'
      })
      if (!isClient()) {
        return this.$post('UserResource/GetUserPage', {}, (data) => {
          // console.log(data)
          // data = []
          if (!_.isArray(data)) {
            data = []
          }
          if (data.length === 0) {
            this.$store.commit('removeTab', 'Home')
            this.$router.replace({
              name: 'Home'
            })
            this.$router.push({
              name: 'Forbidden'
            })
          }
          let roles = data.map(item => item.Name)
          this.$store.commit('setAccountInfo', {
            pageRoles: roles
          })
        })
      }
    },

    // POST请求
    $postBytkie(tkieName, url, ...args) {
      let {
        data,
        success,
        error
      } = {
        data: args.length && _.has(args[0], 'data') ? args[0].data : (!_.isFunction(args[0]) ? args[0] : undefined),
        success: args.length && _.has(args[0], 'success') && _.isFunction(args[0].success) ? args.success : (_.isFunction(args[0]) ? args[0] : args[1]),
        error: args.length && _.has(args[0], 'error') && _.isFunction(args[0].error) ? args.error : (_.isFunction(args[0]) ? args[1] : args[2])
      }

      let host = apiDomain
      if (debug && debugInfo.openDebug) {
        host = debugInfo.apiDomain
      }

      const token = tkieName || getCookie2('login_token')

      // if(!token) {
      //   this.$router.push({name: 'LoginView'})
      //   return false
      // }

      return this.$http.post(host + url, JSON.stringify(data), {
        headers: {
          'Authorization': token
        }
      }).then((response) => {
        // console.log(response)
        try {
          return this.successResponseHandler({
            data: {
              res: response.data,
              Code: response.logiccode,
              url,
            },
            success: success,
            error: error
          })
        } catch (e) {
          if (_.isFunction(error)) {
            error(e)
          }
          this.$notify.error({
            title: '错误',
            message: e.message
          })
          throw e
        }
      }, (e) => {
        _.isFunction(error) ? error(e) : this.$notify.error({
          title: '错误',
          message: e.statusText || '系统错误，请联系管理员！'
        })
        throw e
      })
    },

    // POST请求
    $post(url, ...args) {
      let {
        data,
        success,
        error
      } = {
        data: args.length && _.has(args[0], 'data') ? args[0].data : (!_.isFunction(args[0]) ? args[0] : undefined),
        success: args.length && _.has(args[0], 'success') && _.isFunction(args[0].success) ? args.success : (_.isFunction(args[0]) ? args[0] : args[1]),
        error: args.length && _.has(args[0], 'error') && _.isFunction(args[0].error) ? args.error : (_.isFunction(args[0]) ? args[1] : args[2])
      }
      let host = apiDomain
      if (debug && debugInfo.openDebug) {
        host = debugInfo.apiDomain
      }
      const token = getCookie(ERPCOOKIENAME, this.$store) || getCookie2('login_token')

      // if(!token) {
      //   this.$router.push({name: 'LoginView'})
      // }

      return this.$http.post(host + url, JSON.stringify(data), {
        headers: {
          'Authorization': token
        }
      }).then((response) => {
        try {
          return this.successResponseHandler({
            data: {
              res: response.data,
              Code: {
                code: response.data.logiccode,
                msg: response.data.logicmsg
              }
            },
            success: success,
            error: error
          })
        } catch (e) {
          console.log('err', e)
          if (_.isFunction(error)) {
            error(e)
          }
          this.$notify.error({
            title: '错误',
            message: e.message
          })
          // _.isFunction(error) ? error(e) : this.$notify.error({title: '错误', message: e.message})
          throw e
        }
      }, (e) => {
        _.isFunction(error) ? error(e) : this.$notify.error({
          title: '错误',
          message: e.statusText || '系统错误，请联系管理员！'
        })
        throw e
      })
    },

    // ajax请求成功对code进行处理
    successResponseHandler({
      data,
      success,
      error,
      url
    } = {}) {
      if (data.res) {
        switch (data.res.code) {
          case 200:
            globalInitialLoginSuccess = true;
            return _.isFunction(success) ? (success(!_.isUndefined(data.res.data) ? data.res.data : null, data.Code) || {}) : data.res.data
          case 110:
            console.log('监控110');
            if (window.location.href.indexOf('/#/login') !== -1) {
              return;
            }
            this.$store.commit('setPageLoading', false)
            if (window.__ZNLShowReloginAlert === true) {
              return
            }
            window.__ZNLShowReloginAlert = true
            if (globalInitialLoginSuccess) {
              this.$message({
                message: '账号验证失败, 请重新登陆',
                type: 'warning'
              });
            }

            if (!isClient()) {
              this.$store.commit("removeAllTab");
              this.onClearStorage()
              delCookie('login_token');
              this.$router.push({
                name: "LoginView", query: {
                  date: new Date().getTime()
                }
              })
              console.log('1111 url is ', url);
              console.log("1111 data is ", data);
              console.log('账号验证失败, 请重新登陆 this.$route is ', this.$route);
              return false;
            } else {
              delCookie(ERPCOOKIENAME)
              window._reLoginCount = (window._reLoginCount || 0) + 1;
              if (this.$root.login) {
                this.$root.login(true)
              } else if (window._reLoginCount && window._reLoginCount > 10) {
                this.$message({
                  message: '重新登陆次数已经超过次，仍然无法连接服务器，请尝试重启软件',
                  type: 'error'
                });
              } else {
                this.userLogin(true).then(() => {
                  window.__ZNLShowReloginAlert = false
                  this.$message({
                    message: '重新登录成功',
                    type: 'success'
                  })
                })
              }
            }
            return false
          case 112:
            let pageAlert = this.$store.state.pageLoading
            console.log('监控112，', pageAlert, this.$destroy)
            if (pageAlert) {
              this.$store.commit('setPageLoading', false)
              this.$alert('您的登录状态失效,请重新登录！', '提示', {
                showClose: false,
                confirmButtonText: '重新登录',
                callback: () => {
                  this.updateClientSessionid()
                }
              })
            }
            return
          case 120:
          case 130:
          case 140:
            errorMsg = '系统异常，请稍后再试'
            break
          case 403:
            errorMsg = '您没有权限使用该功能，请联系主账号授权！'
            break
          case 404:
            errorMsg = '页面未找到'
            break
          case 500:
            errorMsg = '内部错误，请稍后再试'
        }

        if (data.res.code === 110 || data.res.code === 403) {
          _.isFunction(error) && error(data.res)
          throw new Error(data.res.msg)
        } else {
          errorMsg = data.res.msg
        }
      } else {
        var errorMsg = '数据异常，请稍后再试'
      }
      if (errorMsg) {
        throw new Error(errorMsg)
      }
    },
    // 将服务器列配置转换为表格控件列配置对象
    flexGridColumnsHandler(columnConfigs = []) {
      let finishColumn = columnConfigs.map(column => {
        return _.defaults({
          'GCGuid': column.GCGuid, // 列的唯一标识GUID
          'name': column.BindField,
          'dataType': column.DataType,
          'binding': column.BindField,
          'width': column.ColumnWidth,
          'header': column.SCTitle || column.CTitle,
          'visible': !!column.IsShow,
          'sort': column.DisplayIndex,
          'isReadOnly': !!column.IsReadonly,
          'isSystem': !!column.IsSystem,
          'authUsers': column.ShowUserGuids,
          'isTag': !!column.IsLable,
          'isFieldSearch': !column.IsLable,
          'IsRequired': !!column.IsRequired,
          'IsUpperCase': !!column.IsUpperCase,
          'IsExprot': !!column.IsExprot,
          'extraConfig': _.isString(column.ExtenValue) && column.ExtenValue.length ? JSON.parse(column.ExtenValue) : {}
        }, {
          'dataType': 1,
          // 'isRequired': false,
          'allowSorting': true,
          'editable': true,
          'authUsers': []
        })
      })
      // this.IsMaincontextmenu(finishColumn) // 非main权限隐藏列配置
      return finishColumn
    },

    // 将表格的列配置对象转换为服务器列配置
    flexGridSettingHandler(columns, pageSize, defaultConfig = {}, parameterConfig) {
      let columnsConfig = _.map(columns, column => {
        var defaultColumnConfig = defaultConfig && _.find(defaultConfig.ColumnConfigs, d => d.BindField === column.binding)
        return _.extend({}, defaultColumnConfig, {
          'GCGuid': column.GCGuid, // 列的唯一标识GUID
          'BindField': column.binding,
          'ColumnWidth': column.width,
          'CTitle': column.header,
          'IsShow': column.visible,
          'DisplayIndex': column.sort,
          'IsReadonly': column.isReadOnly,
          // 'ShowUserGuids': column.authUsers,
          //  'HidedUserGuids': _.filter(this.users, user => !column.authUsers.includes(user.id)).map(user => user.id),
          // 'ExtenValue': column.extraConfig
          'IsExprot': !!column.IsExprot,
          'ExtenValue': _.isString(column.extraConfig) ? column.extraConfig : JSON.stringify(column.extraConfig)
        })
      })
      return _.extend({}, defaultConfig, {
        PageSize: pageSize,
        ColumnConfigs: columnsConfig,
        ParamJosn: parameterConfig
      })
    },

    //根据传入天数，获取几天前的时间
    getDate(days) {
      let nowDate = new Date()
      var newdate = new Date(nowDate - days * 24 * 3600 * 1000)
      var y = newdate.getFullYear()
      var m = newdate.getMonth() + 1
      var d = newdate.getDate()
      let date = y + '-' + m + '-' + d
      return date
    },

    //根据传入天数,获取当月月头与月尾，返回数组
    getfromtoDate(days) {
      let newyear = days.getFullYear()
      let newmonth = days.getMonth()
      if (newmonth > 12) { // 如果当前大于12月，变成下一年
        newmonth = 1
        newyear = newyear + 1
      }
      let newdate = new Date(newyear, newmonth, 1) // 当月起始日 1号
      let Nextdate = new Date(newyear, newmonth + 1, 1) // 次月起始日 1号
      let Enddate = new Date(Nextdate.getTime() - 1000 * 60 * 60 * 24) // 当月最后一天：次月1日-1天
      let date = new Array(newdate, Enddate)
      return date
    },

    toFixed(value, digits = 2, defaultValue = 0) {
      if (value === null || value === undefined || value === '' || isNaN(value)) {
        value = defaultValue
      } else if (typeof (value) !== 'number') {
        value = parseFloat(value)
      }

      return (value % 1 === 0) // check number is integer
        ?
        ('' + value) :
        value.toFixed(digits).replace(/\.?0*$/, '')
    },

    toFloat(value, digits = 6) {
      return parseFloat(this.toFixed(value, digits))
    },

    toInt(value, defaultVal = 0) {
      if (isNaN(value) || !/^\d+$/.test(value)) {
        return defaultVal
      }
      return parseInt(value)
    },

    isNumber(value) {
      if (value === null || value === undefined || value === '' || isNaN(value)) {
        return false
      }
      if (typeof (value) !== 'number') {
        return false
      }
      return true
    },

    ConvertNumber(num) {
      // 转出数据格式
      return _.isNaN(num) || _.isUndefined(num) ? null : this.toFloat(num, 6)
    },

    GetProfitRate(price, buyPrice, rate) { // 获取利润率
      price = this.toFloat(price)
      buyPrice = this.toFloat(buyPrice)
      rate = this.toFloat(rate)
      if (price > 0 && rate > 0) {
        var profitRate = ((price * rate - buyPrice) / (price * rate)) * 100
        return this.toFixed(profitRate, 2) + '%'
      } else {
        return ''
      }
    },

    GetGrossProPrice(buyPrice, qty) { // 获取采购总额
      buyPrice = this.toFloat(buyPrice)
      qty = this.toFloat(qty)
      if (buyPrice > 0 && qty > 0) {
        var grossPro = buyPrice * qty
        return this.toFixed(grossPro, 2)
      } else {
        return ''
      }
    },

    arrayMin(arrs) {
      var min = arrs[0]
      for (var i = 1, ilen = arrs.length; i < ilen; i += 1) {
        if (arrs[i] < min) {
          min = arrs[i]
        }
      }
      return min
    },

    getObject(key) {
      var result = localStorage.getItem(key);
      if (result !== "") {
        return JSON.parse(result)
      } else {
        return null
      }
    },

    setObject(key, val) {
      if (val) {
        localStorage.setItem(key, JSON.stringify(val))
      } else {
        localStorage.setItem(key, "")
      }
    },

    getShoppingCart() {
      const datas = this.getObject("shoppingCart")
      this.$store.commit('setShoppingData', datas || [])
      return datas
    },

    setShoppingCart(obj) {
      let info = this.getShoppingCart();
      if (info === null) {
        this.setObject("shoppingCart", obj)
      } else {
        info = info.concat(obj)
        info = _.sortBy(info, function (item) {
          return item.CompanyName
        }).reverse()
        this.setObject("shoppingCart", info)
      }
    },

    removeShoppingCart(jGuid) {
      if (this.hasValue(jGuid)) {
        let infos = this.getShoppingCart();
        let index = infos.findIndex(x => x.JGUID == jGuid)
        infos.splice(index, 1)
        this.setObject("shoppingCart", infos)
      }
    },

    // JS生成GUID
    getNewGuid() {
      var s = [];
      var hexDigits = "0123456789abcdef";
      for (var i = 0; i < 36; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
      }
      s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
      s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
      s[8] = s[13] = s[18] = s[23] = "-";

      var uuid = s.join("");
      return uuid;
    },

    // localStorage 中存储 50条数据 ，减少请求
    async isSaveStorage(name, url) {
      let config
      if (localStorage.getItem(name)) {
        config = JSON.parse(localStorage.getItem(name))
      } else {
        config = await this.$post(url)
        let data = {
          storageLastTime: new Date().getTime()
        }
        localStorage.setItem(name, JSON.stringify(Object.assign({}, config, data)))
      }
      let arr = Object.keys(localStorage)
      if (arr.length > 50) {
        let str = []
        let allTime = []
        let minNum
        for (var i = 0; i < arr.length; i++) {
          str.push(JSON.parse(localStorage.getItem(arr[i])))
        }
        str.forEach((item) => {
          allTime.push(item.storageLastTime)
        })
        str.forEach((item, index) => {
          if (item.storageLastTime === this.arrayMin(allTime)) {
            minNum = index
          }
        })
        localStorage.removeItem(arr[minNum])
      }

      return config
    },

    // 判断对象是否有值。 undeinfed, null, 空字符串
    hasValue(val) {
      return !_.isUndefined(val) && val !== null && val !== ''
    },

    getWindowHeigth() {
      return document.documentElement.clientHeight || document.body.clientHeight
    },

    DateDiffer(start, end) {
      if (end === null || end === '' || end === undefined) {
        var date = new Date();
        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        var dd = date.getDate();
        end = year + '-' + month + '-' + dd
      }
      let s = new Date(start)
      let d = new Date(end)
      var startDate = s.getFullYear() + '-' + (s.getMonth() + 1) + '-' + s.getDate()
      var endDate = d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate()
      return this.DateDifferNext(endDate, startDate)
    },

    DateDifferNext(sDate1, sDate2) {
      var aDate, oDate1, oDate2, iDays
      aDate = sDate1.split('-')
      oDate1 = new Date(aDate[1] + '-' + aDate[2] + '-' + aDate[0])
      aDate = sDate2.split('-')
      oDate2 = new Date(aDate[1] + '-' + aDate[2] + '-' + aDate[0])
      iDays = parseInt((oDate1 - oDate2) / 1000 / 60 / 60 / 24)
      return iDays > 365
    },

    // 调用 _.find方法，查询列表中指定属性的值。没有则返回空的对象 {}
    findVal(list, fn) {
      let obj = _.find(list, fn)
      if (_.isUndefined(obj) || obj === null) {
        return {}
      } else {
        return obj
      }
    },

    showError(msg) {
      return this.$message({
        message: msg,
        type: 'error'
      })
    },
    showSuccess(msg) {
      return this.$message({
        message: msg,
        type: 'success'
      })
    },

    dataValid(data, checkRule) {
      let isPass = true
      for (var i in checkRule) {
        let item = checkRule[i]
        if (data[item.key] === '' || data[item.key] === null || data[item.key] === undefined) {
          isPass = false
          this.showError(item.msg);
          break;
        }
      }

      return isPass
    },


    // 清楚除去公司，账号的其他存储信息
    onClearStorage() {
      let len = window.localStorage.length
      let keys = []
      for (let i = 0; i < len; i++) {
        // 获取key 索引从0开始
        let getKey = window.localStorage.key(i);
        // 获取key对应的值
        let getVal = window.localStorage.getItem(getKey);
        // 放进数组
        keys.push({
          key: getKey,
          value: getVal
        })
      }
      const clearKeys = keys.filter(item => item.key !== "account" && item.key !== "companyName")
      if (clearKeys.length) {
        clearKeys.forEach(item => {
          const key = item.key
          window.localStorage.removeItem(key)
        })
      }
    }
  },

  mounted() {
    if (this.$parent && this.$parent.$options.componentName === 'znlCol' && this.$parent.span) {
      var targetElement = this.$el
      var parent = this.$parent.$el
      let div = document.createElement('div')
      // if the parents lastchild is the targetElement...
      if (parent.lastChild === targetElement) {
        // add the newElement after the target element.
        parent.appendChild(div)
      } else {
        // else the target has siblings, insert the new element between the target and it's next sibling.
        // console.log(parent, targetElement.nextSibling)
        parent.insertBefore(div, targetElement.nextSibling)
      }

      addClass(div, 'znl-cell')
      setStyle(div, {
        width: (100 / this.$parent.span) + '%'
      })
      div.appendChild(targetElement)
    }
  }
}
