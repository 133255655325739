<template>
  <div class="oss_file">
    <el-col class="file-controller">
      <div class="upload-left">
        <input
          type="file"
          v-if="!isMultiple"
          :name="uploadId"
          :style="{ cursor: disabled ? 'not-allowed' : 'pointer' }"
          :placeholder="selectedFileName"
          :id="uploadId"
          :accept="accept"
          :disabled="uploading2 || disabled"
          value="selectedFileName"
          class="hidden-element"
          @change="fileChange"
          @click="fileClick"
        />
        <input
          type="text"
          class="upload-select-input"
          :placeholder="'请' + selectTips + '···'"
          :style="styleData"
          readonly="readonly"
          :disabled="disabled"
          v-model="selectedFileName"
        />
        <input
          type="file"
          v-if="isMultiple"
          :name="uploadId"
          :style="{ cursor: disabled ? 'not-allowed' : 'pointer' }"
          :placeholder="selectedFileName"
          :id="uploadId"
          :accept="accept"
          multiple="multiple"
          :disabled="uploading2 || disabled"
          value="selectedFileName"
          class="hidden-element"
          @change="fileChange"
          @click="fileClick"
        />

        <el-button
          plain
          class="znl-btn-auxiliary upload-select-btn"
          type="success"
          style="width: 87px"
          :loading="uploading2"
          :disabled="disabledBtn || disabled"
          >{{ uploading2 ? "上传中" : selectTips }}</el-button
        >
      </div>
      <div class="upload-right" v-if="hasClickUpload">
        <el-button
          type="primary"
          :loading="uploading"
          :disabled="disabledBtn"
          class="znl-btn-main"
          @click="onUpload"
          >{{ uploading ? "上传中" : "上传" }}</el-button
        >
      </div>
    </el-col>
    <el-col class="oss_file mt10">
      <div>{{ uploadMsg }}</div>
    </el-col>
    <div style="clear: both;"></div>
  </div>
</template>

<script>
import httpRequest from "./ajax";
import { ERPCOOKIENAME } from "@scripts/config";

const OSS = require("ali-oss");

const createAuthPolicyTokenUrl =
  "https://base-service.bom.ai/api/json/reply/CreateAuthPolicyToken";
const action = "https://bom-ai-read.oss-cn-shenzhen.aliyuncs.com";
const serverUrl = "//bom-ai-read.oss-cn-shenzhen.aliyuncs.com";
const code = "erp-web";
const dir = "erp/";
const reqs = {};

export default {
  name: "upload",
  data() {
    return {
      disabledBtn: false,
      selectedFileName: null,

      region: process.env.VUE_APP_ALI_OSS_REGION,
      AccessKeyID: process.env.VUE_APP_ALI_OSS_CLIENT_ID,
      AccessKeySecret: process.env.VUE_APP_ALI_OSS_CLIENT_SECRET,
      Bukect: process.env.VUE_APP_ALI_OSS_BUKECT,
      ossFileUrl: process.env.VUE_APP_ALI_OSS_FILE_URL,

      uploadId: "uploadfile",
      uploadCode: 0,

      percentage: 0,
      uploading: false,
      uploading2: false,
      uploadMsg: "",
      styleData: {
        width: "",
        marginRight: "2px",
      },

      waitUpload: false, // 等待上传的标记
    };
  },
  props: {
    folder: {
      type: String,
      default: "default",
    },
    width: String,
    fileWidth: String,
    loading: Boolean,
    // 上传文件格式过滤
    accept: String,
    isMultiple: false, // 是否允许多个文件上传
    selectTips: {
      type: String,
      default: () => {
        return "选择文件";
      },
    },
    // 是否有点击上传按钮。false: 选中完文件后即刻出发upload-before
    hasClickUpload: {
      type: Boolean,
      default: () => {
        return true;
      },
    },
    refresh: Boolean,
    refreshClick: Boolean, // 刷新单击事件，通过该事件出发上传到阿里云的动作
    disabled: {
      // 是否禁用上传组件
      type: Boolean,
      default: () => {
        return false;
      },
    },
  },
  watch: {
    refresh: {
      handler(val) {
        this.onRefresh();
      },
      immediate: true,
    },
    selectedFileName(val) {
      this.disabledBtn = val === null;
    },
    refreshClick(val) {
      if (this.waitUpload) {
        this.waitUpload = false;
        // this.doUpload();
        this.doUploadNew();
      }
    },
  },
  methods: {
    // 处理上传，将文件上传到阿里云
    doUpload() {
      const _this = this;
      const client = new OSS({
        region: _this.region,
        accessKeyId: this.AccessKeyID,
        accessKeySecret: this.AccessKeySecret,
        // stsToken: 'result.data.data.SecurityToken',
        bucket: _this.Bukect,
      });

      this.disabledBtn = true;
      this.uploading = true;
      if (!this.hasClickUpload) {
        this.uploading2 = true;
      }

      _this.percentage = 0;
      const files = document.getElementById(_this.uploadId).files;
      let finishCount = 0;
      let filesArry = [];
      _.each(files, (file) => {
        this.uploadToOSS(
          file,
          client,
          (fileUrl) => {
            finishCount++;
            filesArry.push({
              fileName: file.name,
              fileSize: file.size,
              fileUrl: fileUrl,
            });

            if (finishCount === files.length) {
              this.onUploadCompleted(file.name, file.size, fileUrl, filesArry);
            }
          },
          (err) => {
            _this.$emit("upload-error", err.message);
            console.log(err);
            _this.$message({
              message: "上传出错。错误信息：" + err.message,
              type: "error",
            });
            _this.disabledBtn = false;
            _this.uploading = false;
            _this.uploading2 = false;
          }
        );
      });
    },
    //新版上传
    async doUploadNew() {
      const _this = this;
      const files = document.getElementById(_this.uploadId).files;
      const postFiles = Array.prototype.slice.call(files);

      let finishCount = 0;
      let filesArry = [];

      postFiles.forEach(async (rawFile) => {
        // 随机命名
        let randomName =
          this.folder +
          "/" +
          this.random_string(6) +
          "_" +
          new Date().getTime() +
          "." +
          rawFile.name.split(".").pop();
        //上传前处理
        var data = await this.beforeUpload(randomName);
        if (!data) {
          _this.$message({
            message: "获取令牌失败",
            type: "error",
          });
          return;
        }

        var fileUrl = `${serverUrl}/${data.key}`;
        const { uid } = rawFile;
        const options = {
          headers: {},
          withCredentials: false,
          file: rawFile,
          data: data,
          filename: "file",
          action: action,
          onProgress: (e) => {},
          onSuccess: (res) => {
            delete reqs[uid];
            finishCount++;
            filesArry.push({
              fileName: rawFile.name,
              fileSize: rawFile.size,
              fileUrl: fileUrl,
            });

            if (finishCount === files.length) {
              this.onUploadCompleted(
                rawFile.name,
                rawFile.size,
                fileUrl,
                filesArry
              );
            }
          },
          onError: (err) => {
            console.log("upload fail", err);
            delete reqs[uid];
            _this.$emit("upload-error", err.message);
            _this.$message({
              message: "上传出错。错误信息：" + err.message,
              type: "error",
            });
            _this.disabledBtn = false;
            _this.uploading = false;
            _this.uploading2 = false;
          },
        };
        const req = httpRequest(options);
        reqs[uid] = req;
        if (req && req.then) {
          req.then(options.onSuccess, options.onError);
        }
      });
    },
    //上传文件之前获取令牌
    async beforeUpload(fileName) {
      //获取erp的token
      var erpToken =
        this.getCookie(ERPCOOKIENAME, this.$store) || getCookie2("login_token");
      // var token = encodeURIComponent(erpToken.slice(6));
      var token = erpToken.slice(6);
      var xhr = new XMLHttpRequest();
      xhr.open("post", createAuthPolicyTokenUrl, false);
      xhr.setRequestHeader("Content-type", "application/json");
      var param = JSON.stringify({
        code: code,
        token: token,
      });
      xhr.send(param);
      let result = null;
      if (xhr.status == 200) {
        var policyToken = JSON.parse(xhr.responseText).policyToken;
        result = {
          key: `${dir}${fileName}`,
          OSSAccessKeyId: policyToken.accessKeyId,
          policy: policyToken.policy,
          signature: policyToken.signature,
        };
      }
      return result;
    },
    // 上传到阿里云oss
    uploadToOSS(file, client, onSuccess, onError) {
      // 随机命名
      let randomName =
        this.folder +
        "/" +
        this.random_string(6) +
        "_" +
        new Date().getTime() +
        "." +
        file.name.split(".").pop();
      // 上传
      client
        .multipartUpload(randomName, file, {
          progress: function* (percentage, cpt) {
            // 上传进度
            // console.log(percentage, cpt)
            // _this.percentage = percentage
          },
        })
        .then((results) => {
          // 上传完成
          let filepath = this.ossFileUrl + results.name;
          onSuccess(filepath);
        })
        .catch((err) => {
          if (typeof onError === "function") {
            onError(err);
          }
        });
    },

    // 随机生成文件名
    random_string(len) {
      len = len || 32;
      var chars = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678";
      var maxPos = chars.length;
      var pwd = "";
      for (let i = 0; i < len; i++) {
        pwd += chars.charAt(Math.floor(Math.random() * maxPos));
      }
      return pwd;
    },
    // 点击上传按钮
    onUpload() {
      const f = document.getElementById(this.uploadId);
      let fileName = "";
      let fileSize = 0;
      let filesArry = [];
      if (f.files !== undefined && f.files.length > 0) {
        fileName = f.files[0].name;
        fileSize = f.files[0].size;

        _.each(f.files, (file) => {
          filesArry.push({
            fileName: file.name,
            fileSize: file.size,
          });
        });
      }

      this.waitUpload = true;
      this.$emit("upload-before", fileName, fileSize, filesArry);
    },
    // 文件成功上传到阿里云后
    onUploadCompleted(fileName, fileSize, fileUrl, filesArry) {
      this.disabledBtn = false;
      this.uploading = false;
      this.uploading2 = false;
      this.$emit("upload-completed", fileName, fileSize, fileUrl, filesArry);
    },
    fileChange(file) {
      let files = file.target.files;
      if (files.length > 0) {
        let namestr = "";
        _.each(files, (f) => {
          namestr += '"' + f.name + '" ';
        });
        this.selectedFileName = namestr;
        // this.selectedFileName = files[0].name
      } else {
        this.selectedFileName = "";
      }
      this.disabledBtn = false;
      this.uploadMsg = "";

      if (!this.hasClickUpload) {
        // 当选中完立即上传后
        let fileName = "";
        let fileSize = 0;
        if (files !== undefined && files.length > 0) {
          fileName = files[0].name;
          fileSize = files[0].size;
        }
        this.waitUpload = true;
        this.$emit("upload-before", fileName, fileSize);
      }
    },
    fileClick(file) {
      // debugger
      this.onRefresh();
    },
    onRefresh() {
      this.uploadMsg = "";
      if (this.hasClickUpload) {
        this.selectedFileName = null;
      }
      let _o = document.getElementById(this.uploadId);
      if (_o !== undefined && _o !== null) {
        _o.value = "";
      }
    },
    getCookie(name, store) {
      if (store) {
        return store.state[name];
      }
      return "";
    },
  },
  created() {
    this.uploadId = "uploadfile_" + (Math.random() * 100000).toFixed();
    this.styleData.width = this.width;
    if (this.disabled) {
      this.styleData.cursor = "not-allowed";
    }
  },
};
</script>
<style lang="scss" scoped>
  .oss_file {
    height: 30px;
    .mt10 {
      margin-top: 5px;
    }
    .upload-left, .upload-right {
      position: absolute;
    }
    .upload-left {
      .upload-select-input {
        padding: 3px 6px;
        padding-left: 10px;
        border: 1px solid #dcdfe6;
        width: 78%;
        height: 30px;
        line-height: 25px;
        border-left: 3px solid #3fb7eb;
        background: #fafafb;
        border-radius: 2px;
      }
      .hidden-element {
        display: block;
        position: absolute;
        top: 0;
        opacity: 0;
        height: 32px;
        width: 100%;
        background: red;
        cursor: pointer;
      }
    }
    .upload-right {
      width: 90px;
      margin-top: 1px;
      right: 2px;
      button {
        width: 85px;
      }
    }
  }
</style>
