import {
  AccountManage as AccountManageOld,
  AccountMng,
  BaseConfig,
  SysConfiguration,
  BackupAndInit,
  RegisterInfo,
  TemplateOfPrint,
  MultiCompanies,
  DataRecycle,
  RoleManage,
  UserSubCompany,
  NumRegist
} from '~/views/Account'
import { Rate } from '~/views/Crm'
import { SysBillNoRule, SysWorkflowSet } from '~/views/Sys'
import { BomCheckUserSet } from '~/views/Bom'
import { Forbidden } from '~/views'

export default {
  path: '/account',
  name: 'Account',
  component: AccountManageOld,
  meta: {
    alwaysShow: true,//一直显示
    title: '系统设置',
    src: require('~assets/images/left_nav_pic/set_ic.png'),
    alias: '系统设置',
    authName: '系统设置',
    icon: 'icon-left_nav_set_ic'
  },
  children: [
    {
      path: 'BaseConfig',
      name: 'Account/BaseConfig',
      component: BaseConfig,
      meta: {
        alwaysShow: true,
        title: '基本设置',
        authName: '基本设置'
      }
    },
    // {
    //   path: 'AccountManageOld',
    //   name: 'Account/AccountManageOld',
    //   component: AccountManageOld,
    //   meta: {
    //     title: '账号管理（old）',
    //     authName: '账号管理'
    //   }
    // },
    {
      path: 'AccountManage',
      name: 'Account/AccountManage',
      component: AccountMng,
      meta: {
        title: '账号管理',
        authName: '账号管理'
      }
    },
    {
      path: 'RegisterInfo',
      name: 'Account/RegisterInfo',
      component: RegisterInfo,
      meta: {
        title: '账号注册资料',
        authName: '账号管理'
      }
    },
    {
      path: 'TemplateOfPrint',
      name: 'Account/TemplateOfPrint',
      component: TemplateOfPrint,
      meta: {
        title: '打印模板',
        authName: '账号管理'
      }
    },
    {
      path: 'SysConfiguration',
      name: 'Account/SysConfiguration',
      component: SysConfiguration,
      meta: {
        title: '系统参数设置',
        authName: '账号管理'
      }
    },
    {
      path: 'BackupAndInit',
      name: 'Account/BackupAndInit',
      component: BackupAndInit,
      meta: {
        title: '备份及初始化',
        authName: '账号管理'
      }
    },
    {
      path: 'MultiCompanies',
      name: 'Account/MultiCompanies',
      component: MultiCompanies,
      meta: {
        title: '多公司管理',
        authName: '账号管理'
      }
    },
    {
      path: 'DataRecycle',
      name: 'Account/DataRecycle',
      component: DataRecycle,
      meta: {
        title: '数据回收站',
        authName: '账号管理'
      }
    },
    // {
    //   path: 'ZDemo',
    //   name: 'Account/ZDemo',
    //   component: ZDemo,
    //   meta: {
    //     title: 'Z测试模块',
    //     authName: '账号管理'
    //   }
    // },
    // {
    //   path: 'Forbidden',
    //   name: 'Account/Forbidden',
    //   component: Forbidden,
    //   meta: {
    //     title: '没有权限',
    //     authName: '账号管理'
    //   }
    // },
    //#region 原路由，已注释

    // {
    //   path: 'role',
    //   name: 'Account/Role',
    //   component: RoleManage,
    //   meta: {
    //     title: '角色管理',
    //     // display: false
    //     authName: '角色管理'
    //   }
    // },
    // {
    //   path: 'crmRate',
    //   name: 'Crm/Rate',
    //   component: Rate,
    //   meta: {
    //     title: '汇率设置',
    //     authName: '汇率设置'
    //   }
    // },
    // {
    //   path: 'UserSubCompany',
    //   name: 'Account/UserSubCompany',
    //   component: UserSubCompany,
    //   meta: {
    //     title: '多公司管理',
    //     display: false
    //   // authName: 'Mod.账号管理'
    //   }
    // },
    // {
    //   path: 'NumRegist',
    //   name: 'Account/NumRegist',
    //   component: NumRegist,
    //   meta: {
    //     title: '账号注册资料',
    //     display: false
    //   }
    // },
    // {
    //   path: 'sysbillnorule',
    //   name: 'Sys/SysBillNoRule',
    //   component: SysBillNoRule,
    //   meta: {
    //     title: '单号规则设置',
    //     authName: '单号规则设置'
    //   }
    // },
    // {
    //   path: 'SysWorkflowSet',
    //   name: 'Sys/SysWorkflowSet',
    //   component: SysWorkflowSet,
    //   meta: {
    //     title: '单据流程设置',
    //     authName: '单据流程设置'
    //   }
    // },
    // {
    //   path: 'BomCheckUserSet',
    //   name: 'Bom/BomCheckUserSet',
    //   component: BomCheckUserSet,
    //   meta: {
    //     title: '配置查价员',
    //     authName: '查价员配置',
    //     display: false
    //   }
    // }

    //#endregion
  ]
}
