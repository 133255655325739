<template>
  <!-- 汇总库存 -->
  <znl-gridmodule
    ref="flexGrid"
    :header="header"
    :table-handle="32"
    class="znl-stock-summary-grid znl-m-all-stock"
    height="100%"
    :min-height="maxHeight"
    loading-type="animation"
    :columns="columns"
    :item-source="itemSource"
    :gridtype="gridtype"
    :is-init="isInit"
    :is-fields-search="false"
    :is-multi-rows-check="true"
    :is-show-rightbtns="false"
    :page-size="pageSize"
    :page-index="pageIndex"
    :total-count="totalCount"
    :search-fields="searchModel"
    :role="role"
    :on-init="onInit"
    :on-refresh="onRefresh"
    :on-search="onSearch"
    :on-save-setting="onSaveSetting"
    :on-save-config="onSaveConfig"
    :on-reset-setting="onResetSetting"
    :on-page-changed="onBindData"
    :show-delete-row-right-menu="false"
    :has-znl-actions="true"
    :is-multi-rows-check-fixed="false"
    :is-activity="isActivity"
    checkboxBindingKey="SUMGuid"
    :loading="loading"
    @select-change="selectChange"
    :on-reset-params="onResetParams"
    @page-loading="
      (val) => {
        $emit('page-loading', val);
      }
    "
    @switch-to-hide="
      (val) => {
        $emit('switch-to-hide', val);
      }
    "
    @cell-click="onCellClick"
  >
    <div slot="heading-slot" class="box_summary">
      <!-- <el-checkbox v-model="searchFields.IsWarning" @change="reSearch">预警</el-checkbox>
      <el-checkbox v-model="searchFields.IsExtend" @change="reSearch">推广</el-checkbox>
      <el-checkbox v-model="searchFields.IsSubscription" @change="reSearch">订阅</el-checkbox>-->

      <znl-input
        form-type="input"
        placeholder="型号"
        :clearable="true"
        width="120px"
        size="mini"
        layout="left"
        inp-perc="100%"
        :border="true"
        v-model="internalSearchCondition.Model"
        type="text"
        @keyup.enter.native="onInternalSearch()"
      ></znl-input>
      <znl-input
        form-type="input"
        placeholder="品牌"
        :clearable="true"
        width="120px"
        size="mini"
        layout="left"
        inp-perc="100%"
        :border="true"
        v-model="internalSearchCondition.Brand"
        type="text"
        @keyup.enter.native="onInternalSearch()"
      ></znl-input>
      <znl-input
        form-type="input"
        placeholder="封装"
        :clearable="true"
        width="120px"
        size="mini"
        layout="left"
        inp-perc="100%"
        :border="true"
        v-model="internalSearchCondition.Packaging"
        type="text"
        @keyup.enter.native="onInternalSearch()"
      ></znl-input>
      <znl-input
        form-type="input"
        placeholder="年份"
        :clearable="true"
        width="120px"
        size="mini"
        layout="left"
        inp-perc="100%"
        :border="true"
        v-model="internalSearchCondition.MakeYear"
        type="text"
        @keyup.enter.native="onInternalSearch()"
      ></znl-input>

      <znl-button
        type="multiple"
        style-type="mac"
        class="box-left-btn search-btn"
      >
        <znl-button-menu @click="onInternalSearch()">
          <i class="iconfont icon-search_ic"></i>
          <span>搜索</span>
        </znl-button-menu>
      </znl-button>

      <znl-button style-type="mac" :height="22" @click="onMarketSearch()">
        <i class="iconfont icon-search_ic"></i>
        <span>市场查货</span>
      </znl-button>
      <!-- <znl-button
        style-type="mac"
        :height="22"
        :btns="qdw_btns"
        @click="onUploadQDW"
        tip="根据搜索条件上传"
        style="margin-right: 5px"
      >
        <span>
          <i class="iconfont icon-table_set_btn_n"></i> 上传到抢单王商品
        </span>
      </znl-button> -->
      <!-- <znl-button
        style-type="mac"
        :height="22"
        @click="onOpenSetExtend()"
        v-if="hasRes('SetExtendStock')"
      >
        <i class="iconfont icon-table_set_btn_n"></i>
        <span>设置推广类型</span>
      </znl-button>-->

      <!-- v-if="hasRes('SetSubscription')" -->
      <!-- <znl-button style-type="mac" :height="22" @click="onOpenSetSubscription()" v-if="false">
        <i class="iconfont icon-table_set_btn_n"></i>
        <span>设置订阅类型</span>
      </znl-button>-->

      <!-- <znl-button style-type="mac" :height="22" @click="onMarking()" v-if="hasRes('SetMarking')">
        <i class="iconfont icon-float_edit_ic"></i>
        <span>打标记</span>
      </znl-button>-->

      <!-- 设置订阅库存  v-if="hasRes('SetSubscription')" -->
      <!-- <znl-button style-type="main" :height="22" @click="onSubSetting()" v-if="false">
        <i class="iconfont icon-table_set_btn_n"></i>
        <span>订阅设置</span>
      </znl-button>-->

      <!-- v-if="hasRes('SetSubscription')" -->
      <!-- <znl-button style-type="main" :height="22" @click="onApproval()" v-if="false">
        <i class="iconfont icon-admin_user_ic"></i>
        <span>审批订阅客户</span>
      </znl-button>-->

      <!-- <znl-float-list style-type="mac" :lists="moreBtns" v-show="isShowMoreBtn(moreBtns)" class="znl-float-list_erp">
        <znl-button style-type="mac" :height='22'>
          <span><i class="iconfont icon-left_nav_show_btn"></i>更多</span>
        </znl-button>
      </znl-float-list>-->
      <znl-button
        style-type="main"
        class="save-self-btn"
        :height="22"
        @click="onBatchSave()"
      >
        <i class="iconfont icon-save_btn_ic"></i>
        <span>保存修改</span>
      </znl-button>

      <znl-button style-type="mac" :height="22" @click="onDelete()">
        <i class="iconfont icon-delete_ic"></i><span>删除</span>
      </znl-button>
      <!-- v-if="hasRes('SetSubscription')" -->
      <znl-button style-type="mac" :height="22" @click="onExport()" v-if="true">
        <i class="iconfont icon-leading-out-wh_btn_i"></i>
        <span>导出</span>
      </znl-button>

      <a
        style="text-decoration: underline; margin-left: 3px; margin-right: 3px"
        @click="onCheckInfo()"
        title="已选中条数"
        >已选择 ({{ selectedRows.length }}) 条</a
      >
      <a
        @click="onCleaSelectCount()"
        style="text-decoration: underline; margin-left: 5px"
        title="清除选中条数"
        >清除</a
      >
      <el-button
        type="warning"
        :loading="isSummary"
        @click="onStockSummary()"
        size="mini"
        style="padding: 4px 9px"
        title="重新从批次库存汇总库存量。汇总过程可能较长，请耐心等待"
        >手动汇总</el-button
      >

      <el-tooltip class="item" effect="dark" placement="bottom">
        <div slot="content" class="stock-summary-field-spec">
          <span>销售价</span
          >：如果打开了同步到直营店的开关，该价格定义为未税售价。<br />
          <span>是否可含税出</span
          >：直营店使用。表示该物料是否可以含税出。<br />
          <span>是否整包卖</span
          >：直营店使用，表示该无聊是否按整包出，未勾选时，表示可以拆包。<br />
        </div>
        <a>字段说明</a>
      </el-tooltip>
      <label>
        <el-checkbox
          v-model="searchFieldEx.onlySearchNoSalesPrice"
          @change="onBindData(1)"
        >
          只查询没有销售价的库存
        </el-checkbox>
      </label>

      <el-button
        v-if="this.$store.state.accountInfo.IsMainAccount"
        type="warning"
        @click="onShowSetSalesPrice()"
        size="mini"
        style="padding: 4px 9px; margin-left: 40px"
        >一键设置销售价</el-button
      >
    </div>
    <!-- <div slot="znl-footer-slot"  class="total-data-footer">
      <el-row>
        <el-col>
          <span>
            <el-tooltip placement="top"  effect="light">
              <div slot="content">按照搜索条件查询出来的所有结果集汇总<br />库存可用量 = 可用量的总和<br/> 总成本      = (成本均价 x 可用量) 总和</div>
              <a @click="onSumData()" style="text-decoration:underline; margin-left:3px;margin-right:5px;" title="按搜索条件">统计库存成本</a>
            </el-tooltip>
          </span>
          <span v-show="this.isShow">
          <span>
            库存可用量: <span class="stsPurchaseIn-amount">{{this.toFloat(this.SumSearchList.SumInvQty,2)}} </span>&nbsp;&nbsp;
            <span>|&nbsp;&nbsp;</span>
          </span>
         </span>
        <span v-show="this.isShow">
          <span>
            总成本: <span class="stsPurchaseIn-amount">{{this.toFloat(this.SumSearchList.SumCostPrice,6)}} </span>&nbsp;&nbsp;
            <span>&nbsp;&nbsp;</span>
          </span>
        </span>
        </el-col>
      </el-row>
    </div>-->
    <!-- <stock-summary-edit
      :title="editTitle"
      :editvisible="editVisible"
      edit-type="edit"
    >
    </stock-summary-edit>-->
    <stock-extend-set
      :visible="stockExtendVisible"
      :checked-rows="checkedRows"
      :search-condition="searchCondition"
      @close="
        () => {
          stockExtendVisible = false;
        }
      "
      @confirm="
        (v) => {
          (stockExtendVisible = false),
            v && this.onRefresh() && this.onCleaSelectCount();
        }
      "
    ></stock-extend-set>

    <!-- 库存页面组件 -->

    <!-- <export-Format
      :visible="stockExtendVisible"
      :checked-rows="checkedRows"
      :search-condition="searchCondition"
      @close="()=>{stockExtendVisible=false}"
      @confirm="(v)=>{stockExtendVisible=false}"
     >
    </export-Format>-->
    <znl-dialog-mini
      :visible.sync="dialogMiniVisible"
      :options="options"
      :show-close="false"
      :close-on-click-modal="false"
      ref="znlDialogMini"
      class="mini-dialog"
    >
      <div>
        <el-row class="mleft" style="width: 100px">
          <el-col>
            <el-radio
              v-model="stype"
              label="4"
              @change="extendTypeChange(4)"
              class="type4"
              >正品企业</el-radio
            >
          </el-col>
          <el-col>
            <el-radio
              v-model="stype"
              label="8"
              @change="extendTypeChange(8)"
              class="type4"
              >正品物料</el-radio
            >
          </el-col>
          <el-col>
            <el-radio
              v-model="stype"
              label="6"
              @change="extendTypeChange(6)"
              class="type6"
              >保证有料</el-radio
            >
          </el-col>
          <el-col>
            <el-radio
              v-model="stype"
              label="9"
              @change="extendTypeChange(9)"
              class="type9"
              >优势推广</el-radio
            >
          </el-col>
          <el-col>
            <el-radio
              v-model="stype"
              label="0"
              @change="extendTypeChange(-1)"
              :disabled="disabledSetDown"
              >取消推广</el-radio
            >
          </el-col>
        </el-row>
      </div>
      <div
        class="myshade"
        :style="shadeLeft"
        v-on:click="dialogMiniVisible = false"
        v-on:mouseenter="dialogMiniVisible = false"
      ></div>
      <div
        class="myshade"
        :style="shadeRight"
        v-on:click="dialogMiniVisible = false"
        v-on:mouseenter="dialogMiniVisible = false"
      ></div>
    </znl-dialog-mini>

    <subscription-set
      :visible="stockSubscrptVisible"
      :checked-rows="checkedRows"
      :search-condition="searchCondition"
      @close="
        () => {
          stockSubscrptVisible = false;
        }
      "
      @confirm="
        (v) => {
          (stockSubscrptVisible = false),
            v && this.onRefresh() && this.onCleaSelectCount();
        }
      "
    ></subscription-set>

    <znl-dialog-mini
      :visible.sync="dialogMiniSubVisible"
      :options="options"
      :show-close="false"
      class="mini-dialog"
    >
      <div>
        <el-row class="mleft" style="width: 100px">
          <el-col v-for="st in subscriptTypeOptions" :key="st.key">
            <el-radio
              v-model="subscriptTypeRadio"
              :label="st.key"
              :class="st.class"
              @change="onSetSubscription"
              >{{ st.value }}</el-radio
            >
          </el-col>

          <el-col>
            <el-radio
              v-model="subscriptTypeRadio"
              :label="-1"
              @change="onSetSubscription(-1)"
              :disabled="disabledSetCancel"
              >取消订阅</el-radio
            >
          </el-col>
        </el-row>
      </div>
      <div
        class="myshade"
        :style="shadeLeft"
        v-on:mouseenter="dialogMiniSubVisible = false"
      ></div>
      <div
        class="myshade"
        :style="shadeRight"
        v-on:mouseenter="dialogMiniSubVisible = false"
      ></div>
    </znl-dialog-mini>

    <znl-dialog-mini
      :visible.sync="dialogMiniWarningVisible"
      :options="options"
      :show-close="false"
      class="mini-dialog"
    >
      <div style="font-size: 12px">
        <el-row class="mleft" style="width: 125px">
          <el-col class="type4" v-if="isWarning">已触发预警！</el-col>
          <el-col>
            当前库存：
            <span class="warn-left">{{ invQty }}</span>
          </el-col>
          <el-col>
            库存上限：
            <span class="warn-left">{{ invQtyMax }}</span>
          </el-col>
          <el-col>
            库存下限：
            <span class="warn-left">{{ invQtyMin }}</span>
          </el-col>
        </el-row>
      </div>
      <div
        class="myshade"
        :style="shadeLeft"
        v-on:mouseenter="dialogMiniWarningVisible = false"
      ></div>
      <div
        class="myshade"
        :style="shadeRight"
        v-on:mouseenter="dialogMiniWarningVisible = false"
      ></div>
    </znl-dialog-mini>

    <step-price
      ref="StepPrice"
      :visible="stepPriceVisible"
      :officalPriceParam="officalPriceParam"
      :sumGuid="sumGuid"
      :sales-price="salesPrice"
      open-from="StockSummary"
      title="设置阶梯报价"
      @close="
        () => {
          stepPriceVisible = false;
        }
      "
      @confirm="
        (v) => {
          (stepPriceVisible = false),
            v && this.onRefresh(),
            this.onCleaSelectCount();
        }
      "
    ></step-price>

    <stk-stock-marking
      ref="StkStockMarking"
      v-if="setMarkingVisible"
      markingType="stockSummary"
      height="50%"
      :visible="setMarkingVisible"
      :sumGuids="sumGuids"
      :markGuids="sumGuids"
      :stockSummaryParam="searchCondition"
      :extend-type-selected="selectedSType"
      :subscript-type-selected="selectedSubtype"
      :mark-names-selected="selectedMarkNames"
      :isRefresh="!refreshSetMarking"
      :has-extend-right="hasExtendRight"
      :has-subscript-right="hasSubscriptRight"
      @close="
        () => {
          setMarkingVisible = false;
        }
      "
      @confirm="onStockMarkingConfirm"
    ></stk-stock-marking>

    <bom-ai-login
      :visible="bomaiVisible"
      @close="
        () => {
          bomaiVisible = false;
        }
      "
      @confirm="
        () => {
          (bomaiVisible = false), openSubSetting();
        }
      "
    ></bom-ai-login>

    <!-- 导出库存格式 -->
    <export-format
      :columns="setColumns"
      :saveConfigURL="setSaveConfigURL"
      :defaultConfigColumns="setDefaultConfigColumns"
      :visible="exportFormatVisible"
      actionServiceName="StockSummary"
      :searchCondition="searchCondition"
      :isShowMergeCondition="false"
      @close="
        () => {
          exportFormatVisible = false;
        }
      "
      @confirm="
        (v) => {
          exportFormatVisible = false;
        }
      "
    ></export-format>
    <!--已选择库存列表-->
    <stk-check-info
      ref="stkCheckInfo"
      v-if="showStkCheckInfo"
      :visible="showStkCheckInfo"
      @checkInfo="
        (val) => {
          this.onSeletedInfo(val);
        }
      "
      @close="
        () => {
          showStkCheckInfo = false;
        }
      "
    ></stk-check-info>
    <!--上传图片-->
    <!-- <image-upload
      :visible="imageUploadVisible"
      @close="()=>{this.imageUploadVisible = false}"
      @confirm="()=>{this.imageUploadVisible = false,this.onBindData(this.pageIndex)}"
    ></image-upload>-->
    <!-- 显示图片 -->
    <!-- <show-image
      :visible="imageBoxVisible"
      :image-list="imageList"
      :title="imageTitle"
      :has-delete="hasRes('StockImage')"
      @close="(imgGuids)=>{onShowImageClose(imgGuids),this.onBindData(this.pageIndex)}"
    ></show-image>-->
    <batch-set-sales-price
      v-if="isShowSetSalesPrice"
      :visible="isShowSetSalesPrice"
      @close="(v) => onSalesPriceClose(v)"
    ></batch-set-sales-price>
  </znl-gridmodule>
</template>
<script>
import stockSummaryEdit from "@c_modules/StkSum/StockSummaryEdit";
import stockExtendSet from "@c_modules/StkSum/StockExtendSet";
import subscriptionSet from "@c_modules/StkSum/SubscriptionSet";
import { getCookie } from "~assets/scripts/utils/dom";
import { mixin as getCommonDataMixin } from "~assets/scripts/methods/getCommonData";
import { mixin as common } from "~assets/scripts/methods/common";
import StepPrice from "@c_modules/StkExt/PubStkStockOfficalPrice";
import StkStockMarking from "@c_modules/Stk/StkStockMarking";
import stkSummaryStockTree from "@c_modules/StkSum/stkSummaryStockTree";
import ExportFormat from "@c_modules/StkSum/ExportFormat";
import { openLinkAll } from "@scripts/methods/common";
import ImageUpload from "@c_modules/StkSum/ImageUpload";
import ShowImage from "@c_modules/StkSum/ShowImage";
import BomAiLogin from "@c_modules/Account/BomAILogin";
import StkCheckInfo from "@c_modules/Stk/StkCheckInfo";
import * as urls from "~/lib/urls";
import { BOMAICOOLIENAME } from "@scripts/config";
import BatchSetSalesPrice from "./BatchSetSalesPrice";

const CONFIG = {
  configURL: "StockSummary/GetConfig",
  saveConfigURL: "StockSummary/SaveConfig",
  resetConfigURL: "StockSummary/ResetConfig",
  searchURL: "StockSummary/Search",
  searchSumURL: "StockSummary/SearchSumList",
  getModelSearchCntURL: "WebSearchLog/GetModelSearch",

  saveRowsURL: "StockSummary/BatchSave",
  setStockExtendURL: "StockSummary/SetStockExtend",
  setSubscriptionURL: "StockSummary/SetSubscription",
  SavePageParamURL: "PageParam/Save", // 保存页面参数配置
  resetParamConfigURL: "StockSummary/ResetParamConfig",
  bomaiApprovalURL: urls.getBomaiApprovalCustomerUrl(), // BOMAI 个人中心 待审批客户
  bomaiBasicSetupUrl: urls.getBomaiBasicSetupkUrl(), // BOMAI 个人中心 订阅设置
  UploadQDW: "StockSummary/UploadBMStock",
  deleteUrl: "StockSummary/Delete", //删除汇总库存
  StockSummaryFromStockUrl: "StockSummary/StockSummaryFromStockAsync", //库存汇总
};

export default {
  name: "StockSummary",
  mixins: [getCommonDataMixin, common, openLinkAll],
  config: CONFIG,
  components: {
    stockSummaryEdit,
    stockExtendSet,
    subscriptionSet,
    StepPrice,
    StkStockMarking,
    stkSummaryStockTree,
    ExportFormat,
    ImageUpload,
    ShowImage,
    BomAiLogin,
    StkCheckInfo,
    BatchSetSalesPrice,
  },
  computed: {
    specialResource() {
      return this.selectOptionsSpecialResource;
    },
  },
  data() {
    return {
      columns: [],
      itemSource: [],
      searchFields: {
        Model: "",
      },
      isShow: false,
      searchFieldEx: {
        isZero: false,
        IsWarning: false, // 预警
        IsExtend: false, // 推广
        IsSubscription: false, // 订阅
        onlySearchNoSalesPrice: false,
      },
      pageSize: 30,
      pageIndex: 1,
      totalCount: 0,
      role: "1",
      gridShow: false,
      moreBtns: [], // 更多操作
      SumSearchList: [], // 统计集合,
      editVisible: false,
      editTitle: "添加物料",
      loading: false,

      // 导入
      pubStockImportVisible: false,
      sumGuids: [],
      Status: true,
      stype: "", // 鼠标悬停时，弹出层选中的类型
      sumGuid: "", // 鼠标悬停时的所在行的sumGuid
      extendType: 0, // 鼠标悬停时所在行的ExtendType
      disabledSetDown: false, // 是否禁用[下架库存]

      stockExtendVisible: false, // 是否显示设置为推广库存
      checkedRows: [], // 选中的行的
      searchCondition: {}, // 查询条件
      shadeLeft: {}, // mini-dialog 左边遮罩层
      shadeRight: {}, // mini-dialog 右边遮罩层
      isEnterCol: false,
      isEnterMini: false,
      options: {},

      dialogMiniVisible: false, // 推广库存显示隐藏
      dialogMiniSubVisible: false, // 订阅库存显示隐藏
      stockSubscrptVisible: false, // 是否显示设置为订阅库存
      dialogMiniWarningVisible: false, // 库存预警

      stypeALL: false, // 全选
      subscriptTypeOptions: [
        { key: 1, value: "A", svalue: "A", class: "color-glod" },
        { key: 2, value: "B", svalue: "B", class: "color-silver" },
        { key: 3, value: "C", svalue: "C", class: "color-copper" },
      ],
      subscriptType: -1, // 鼠标悬停时所在行的订阅类型
      subscriptTypeRadio: -1, // 悬停显示层绑定的值
      disabledSetCancel: false, // 禁用取消订阅
      openBomaiFrom: "", // 记录从哪个按钮打开bomai个人中心

      stepPriceVisible: false, // 是否显示阶梯报价设置界面
      officalPriceParam: {}, // 阶梯报价
      salesPrice: null, // 销售定价

      invQtyMax: 0, // 库存上限
      invQtyMin: 0, // 库存下限
      invQty: 0, // 当前库存量
      isWarning: false, // 是否已预警

      setMarkingVisible: false, // 是否显示打标记页面
      refreshSetMarking: false, // 刷新
      hasExtendRight: false, // 是否有管理推广库存的权限
      hasSubscriptRight: false, // 是否有管理订阅库存的权限

      bomaiVisible: false, // 是否显示bomai登录界面
      gridtype: "action", // 表格类型。 action: 可编辑， base: 不可编辑
      setExportColumnVisible: false,
      addItemSourceList: [],
      exportFormatVisible: false,
      setColumns: [],
      setDefaultConfigColumns: {},
      setSaveConfigURL: "",

      selectedSType: 0, // 当只选中一行时，当前行的推广类型
      selectedSubtype: [], // 当只选中一行时，当前行的订阅类型
      selectedMarkNames: "", // 当只选中一行时，当前行的自定义标签
      isNegative: false, // 负库存
      // imageUploadVisible: false, // 上传图片
      imageBoxVisible: false, // 是否显示型号的图片
      imageList: [], // 待显示的图片
      imageTitle: "",
      parameterConfig: [],
      ParamJosn: [],
      selectedRows: [], // 选中的数据
      showStkCheckInfo: false, // 是否显示已选择库存列表
      qdw_btns: [],
      isSummary: false,
      isShowSetSalesPrice: false, // 一键设置销售价
      //内部搜索条件
      internalSearchCondition: {
        Model: "", //型号
        Brand: "", //品牌
        Packaging: "", //封装
        MakeYear: "", //年份
      },
    };
  },
  props: {
    header: String, // 汇总库存,
    initData: {
      type: Boolean,
      default: false,
    },
    isInit: {
      type: Boolean,
      default: true,
    },
    searchModel: {
      type: Object,
      default: () => {
        return {};
      },
    },
    height: {
      type: String,
      default: "100%",
    },
    maxHeight: Number,
    computedheight: {
      type: Number,
    },
    isActivity: Boolean,
    refresh: Boolean,
    matchOperation: {
      type: String,
      default: "Like",
    },
    ReceiveisZero: Boolean,
    ReceiveisNegative: Boolean,
  },
  watch: {
    searchFields() {
      this.$emit("searchfields-change", this.searchFields.Model);
    },
  },
  methods: {
    onInit: async function (config) {
      if (!config) {
        config = await this.isSaveStorage("stockSummary", CONFIG.configURL);
      }
      // 获取模块打开或者关闭的状态
      // this.GetStatus(config.Status)
      // 获取参数配置
      this.ParamJosn = config.ParamJosn;
      // this.paramConfiguration()
      this.resourceList = config.ResourceList.map((item) => item.Code);
      this.hasExtendRight = this.hasRes("SetExtendStock");
      this.hasSubscriptRight = this.hasRes("SetSubscription");
      let columns = this.flexGridColumnsHandler(config.ColumnConfigs);

      // columns.unshift(
      //   {
      //     name: "ExtendType",
      //     dataType: 1,
      //     binding: "ExtendType",
      //     width: 45,
      //     header: "推广",
      //     visible: true,
      //     isReadOnly: true,
      //     isSystem: true,
      //     isFieldSearch: true,
      //     className: "znl-text-center",
      //     dataMap: [
      //       { key: 4, value: "正企" },
      //       { key: 8, value: "正物" },
      //       { key: 6, value: "有料" },
      //       { key: 9, value: "优势" }
      //     ],
      //     showDropDown: true
      //   },
      //   {
      //     name: "SubscriptionType",
      //     dataType: 5,
      //     binding: "SubscriptionType",
      //     width: 50,
      //     header: "订阅",
      //     visible: false,
      //     isReadOnly: true,
      //     isSystem: true,
      //     isFieldSearch: true,
      //     className: "znl-text-center",
      //     dataMap: this.subscriptTypeOptions,
      //     showDropDown: true,
      //     buttons: [
      //       {
      //         content: "<span>订</span>",
      //         className: row => {
      //           return row.IsExtend
      //             ? "table-columnbtn-disabled table-columnbtn-enable"
      //             : "table-columnbtn-disabled";
      //         }
      //       }
      //     ]
      //   },
      //   {
      //     name: "IsWarning",
      //     dataType: 5,
      //     binding: "IsWarning",
      //     width: 36,
      //     header: "警",
      //     visible: true,
      //     isReadOnly: true,
      //     isFieldsSearch: false,
      //     className: "znl-text-center",
      //     isSystem: true,
      //     buttons: [
      //       {
      //         content: "<span>警</span>",
      //         className: row => {
      //           return row.IsWarning
      //             ? "table-columnbtn-disabled table-columnbtn-enable"
      //             : "table-columnbtn-disabled";
      //         },
      //         click: row => {}
      //       }
      //     ]
      //   }
      // );

      // this.genColExtendHover(
      //   _.find(columns, col => col.binding === "ExtendType")
      // );
      // this.genColSubscriptionHover(
      //   _.find(columns, col => col.binding === "SubscriptionType")
      // );
      // this.genColStepPrice(
      //   _.find(columns, col => col.binding === "StepPriceJson")
      // ); // 阶梯价
      // this.genColWarning(_.find(columns, col => col.binding === "IsWarning")); // 库存预警
      // this.genColMarkNames(_.find(columns, col => col.binding === "MarkNames")); // 标记
      // this.genColImage(_.find(columns, col => col.binding === 'StockImage')) // 库存图片

      let viewBuyPrice = this.hasViewBuyPrice();
      let viewSalesPrice = this.hasViewSalesPrice();

      _.each(columns, (column, index) => {
        // 成本价权限控制
        if (column.binding === "BuyPriceAvg" && viewBuyPrice === false) {
          column.hasRole = false;
        }

        // 权限控制SalesPrice 销售定价 和  StepPriceJson 阶梯价
        if (
          (!viewSalesPrice && column.binding === "SalesPrice") ||
          (!viewSalesPrice && column.binding === "StepPriceJson")
        ) {
          column.hasRole = false;
        }

        if (
          column.binding === "CanChangeTaxRate" ||
          column.binding === "IsWholePacking"
        ) {
          column.isCheckedColumn = true;
          column.isReadOnly = true;
        }

        // if (
        //   column.binding === "StepPriceJson" ||
        //   column.binding === "Model" ||
        //   column.binding === "Brand" ||
        //   column.binding === "Packaging" ||
        //   column.binding === "SalesPrice" ||
        //   column.binding === "MPQ" ||
        //   column.binding === "MakeYear" ||
        //   column.binding === "DealExplain" ||
        //   column.binding === "Quality" ||
        //   column.binding === "DeliveryDate" ||
        //   column.binding === "MaxViewQty"
        // ) {
        //   _.extend(column, {
        //     renderHeader: (h, obj) => {
        //       return h(
        //         "span",
        //         {
        //           attrs: {
        //             class: "znl-share-iconfont"
        //           }
        //         },
        //         [
        //           obj.header,
        //           h("i", {
        //             attrs: {
        //               class: "iconfont icon-share_btn share-icon",
        //               title:
        //                 "标记为推广库存,该列数据会发布到正能量电子网\r标记为订阅库存,该列数据会发布到订阅您的客户"
        //             }
        //           })
        //         ]
        //       );
        //     }
        //   });
        //   if (column.binding === "Model") {
        //     _.extend(column, {
        //       className: "znl-linkAll-column",
        //       renderCell: this.openLinkAll(column)
        //     });
        //   }
        // }
      });
      this.parameterConfig = config.ParamJosn;
      this.defaultConfig = config;
      this.columns = columns;
      this.role = config.Role;
      this.pageSize = config.PageSize > 30 ? this.pageSize : config.PageSize;
      this.moreBtns = [
        // {
        //   name: '打上标记',
        //   // iconName: 'icon-tag_btn_ic',
        //   isShow: this.hasRes('SetMarking'),
        //   click: () => {
        //     this.onMarking()
        //   }
        // },
        // {
        //   name: '发布推广库存',
        //   isShow: this.hasRes('SetExtendStock'),
        //   // iconName: 'icon-leading-in-wh_btn_ic',
        //   click: () => {
        //     this.onOpenSetExtend()
        //   }
        // }, {
        //   name: '发布订阅库存',
        //   isShow: this.hasRes('SetSubscription'),
        //   // iconName: 'icon-leading-in-wh_btn_ic',
        //   click: () => {
        //     this.onOpenSetSubscription()
        //   }
        // },
        // {
        //   name: '取消推广',
        //   isShow: this.hasRes('SetExtendStock'),
        //   click: () => {
        //     this.onCancelExtend()
        //   }
        // },
        // {
        //   name: '取消订阅',
        //   isShow: this.hasRes('SetSubscription'),
        //   click: () => {
        //     this.onCancelSubscript()
        //   }
        // },
        {
          name: "导出库存",
          isShow: true,
          // iconName: 'icon-leading-out-wh_btn_i',
          click: () => {
            this.onExport();
          },
        },
        // {
        //   name: '上传图片',
        //   // isShow: true,
        //   isShow: this.hasRes('StockImage'),
        //   // iconName: 'icon-leading-out-wh_btn_i',
        //   click: () => {
        //     this.onUploadImage()
        //   }
        // }
      ];

      // 判断是否有保存权限
      if (!this.hasRes("Save") && !this.hasRes("StkWarning")) {
        this.gridtype = "base";
      }
      // 库存预警权限
      if (!this.hasRes("StkWarning")) {
        let o1 = _.find(columns, (col) => col.binding === "InvQtyMax");
        let o2 = _.find(columns, (col) => col.binding === "InvQtyMin");
        if (o1) {
          o1.isReadOnly = true;
        }
        if (o2) {
          o2.isReadOnly = true;
        }
      }
      if (this.initData) {
        await this.onBindData();
      }

      this.qdw_btns = [
        {
          name: "根据搜索条件上传商品",
          click: this.onUploadQDW_2click,
        },
      ];
    },

    // 用renderCell生成单元格悬停显示
    genColExtendHover(column) {
      let __this = this;
      column.renderCell = (h, obj) => {
        let row = obj.row;
        let content = '<span class="hover-set">&nbsp;&nbsp;</span>';
        if (row.ExtendType === 4) {
          content = '<span class="type4">正企</span>';
        } else if (row.ExtendType === 8) {
          content = '<span class="type4">正物</span>';
        } else if (row.ExtendType === 6) {
          content = '<span class="type6">有料</span>';
        } else if (row.ExtendType === 9) {
          content = '<span class="type9">优势</span>';
        }
        return h("div", {
          attrs: {
            class: "znl-table-btn",
          },

          domProps: {
            innerHTML: content,
          },

          on: {
            mouseenter(e) {
              if (!__this.hasRes("SetExtendStock")) {
                return false;
              }
              __this.isEnterCol = true;
              __this.sumGuid = row.SUMGuid;
              __this.extendType = row.ExtendType;
              // console.log(obj, e, row.ExtendType, 111)
              if (row.ExtendType) {
                __this.stype = row.ExtendType.toString();
                __this.disabledSetDown = !_.some(
                  [4, 6, 9],
                  (m) => m === row.ExtendType
                );
              } else {
                __this.stype = "";
                __this.disabledSetDown = true;
              }

              __this.dialogMiniVisible = true;
              __this.dialogMiniSubVisible = false;
              __this.dialogMiniWarningVisible = false;
              let option = e.srcElement.getBoundingClientRect();
              __this.options = {
                left: option.left + obj.column.width / 2,
                top: option.top + 14,
              };

              let height = e.view.innerHeight;
              let leftWidth = e.clientX - e.srcElement.offsetWidth;
              let rightL = leftWidth + obj.column.width;
              let rightW = e.view.innerWidth - rightL;
              __this.shadeLeft = {
                width: leftWidth + "px",
                height: height + "px",
              };
              __this.shadeRight = {
                left: rightL + "px",
                height: height + "px",
                width: rightW + "px",
              };
            },
            mouseleave(e) {
              __this.isEnterCol = false;
              // console.log('leave col')
            },
          },
        });
      };
    },
    // 用renderCell生成单元格悬停显示
    genColSubscriptionHover(column) {
      let __this = this;
      column.renderCell = (h, obj) => {
        let row = obj.row;
        let typeNames = [];
        _.each(row.SubscriptionTypeList, (type) => {
          let v = this.findVal(
            __this.subscriptTypeOptions,
            (m) => m.key === type
          ).value;
          v && typeNames.push(v);
        });
        let content = typeNames.join(",");

        if (content === "") {
          content = '<span class="hover-set">&nbsp;&nbsp;</span>';
        } else {
          content = '<span class="qty-color">' + content + "</span>";
        }

        return h("div", {
          attrs: {
            class: "znl-table-btn",
          },

          domProps: {
            innerHTML: content,
          },

          on: {
            mouseenter(e) {
              if (!__this.hasRes("SetSubscription")) {
                return false;
              }
              // __this.sumGuid = row.SUMGuid
              // __this.subscriptType = row.SubscriptionType
              // if (__this.subscriptType !== undefined && __this.subscriptType !== null) {
              //   __this.subscriptTypeRadio = parseInt(__this.subscriptType.toString())
              //   __this.disabledSetCancel = false
              // } else {
              //   __this.subscriptTypeRadio = -2
              //   __this.disabledSetCancel = true
              // }

              // __this.dialogMiniSubVisible = true
              // __this.dialogMiniVisible = false
              // __this.dialogMiniWarningVisible = false
              // let option = e.srcElement.getBoundingClientRect()
              // __this.options = {
              //   left: option.left + obj.column.width / 2,
              //   top: option.top + 14
              // }

              // let height = e.view.innerHeight
              // let leftWidth = e.clientX - e.srcElement.offsetWidth
              // let rightL = leftWidth + obj.column.width
              // let rightW = e.view.innerWidth - rightL
              // __this.shadeLeft = { width: leftWidth + 'px', height: height + 'px' }
              // __this.shadeRight = { left: rightL + 'px', height: height + 'px', width: rightW + 'px' }
            },
          },
        });
      };
    },
    genColStepPrice(column) {
      if (column) {
        column.buttons = [
          {
            content: (row) => {
              if (!this.hasValue(row.StepPriceJson)) {
                return "设置报价";
              }
              var list = JSON.parse(row.StepPriceJson);
              let str = "";
              if (list.length > 0) {
                _.each(list, (item) => {
                  if (str !== "") {
                    str += "";
                  }
                  if (item.IsStandQuote === true) {
                    str +=
                      '<i class="znl-icon iconfont icon-transfer-stand_price"></i>';
                  }
                  str +=
                    '<span class="price-color">¥' +
                    this.toFloat(item.Price, 6) +
                    '</span><span class="qty-color" style="color:#888">(' +
                    this.toFloat(item.Qty, 2) +
                    ")</span>&nbsp;&nbsp;&nbsp;";
                });
                return "<div>" + str + "</div>";
              }
              return "设置报价";
            },
            className: "znl-table-btn-success o-price no-underline",
            when: true,
            click: (row) => {
              // if (row.ExtendType !== 6 && row.ExtendType !== 4) {
              //   return false
              // }
              // if (!this.hasRes('SetExtendStock')) {
              //   this.$message({ message: '您没有设置阶梯价的权限', type: 'error' })f
              //   return false
              // }
              this.officalPriceParam.Model = row.Model;
              this.officalPriceParam.Brand = row.Brand;
              this.officalPriceParam.MakeYear = row.MakeYear;
              this.officalPriceParam.Packaging = row.Packaging;
              this.officalPriceParam.StepPrice = row.StepPriceJson;
              this.officalPriceParam.ExpirationTime = row.ExpirationTime;
              this.salesPrice = row.SalesPrice;
              this.sumGuid = row.SUMGuid;
              this.stepPriceVisible = true;
            },
          },
        ];
        column.dataType = 5;
      }
    },
    genColWarning(column) {
      let __this = this;
      column.renderCell = (h, obj) => {
        let row = obj.row;
        let content = "";

        if ((row.IsWarning || false) === true) {
          content = '<span class="iconfont icon-alarm-wh_btn_ic type4"></span>';
        } else {
          content =
            '<span class="iconfont icon-alarm-wh_btn_ic color-ccc"></span>';
        }

        return h("div", {
          attrs: {
            class: "znl-table-btn",
          },

          domProps: {
            innerHTML: content,
          },

          on: {
            mouseenter(e) {
              if (!__this.hasRes("SetSubscription")) {
                return false;
              }
              __this.sumGuid = row.SUMGuid;
              __this.invQtyMax = row.InvQtyMax;
              __this.invQtyMin = row.InvQtyMin;
              __this.invQty = row.InvQty;

              __this.isWarning = row.IsWarning || false;

              __this.dialogMiniSubVisible = false;
              __this.dialogMiniVisible = false;
              __this.dialogMiniWarningVisible = true;
              let option = e.srcElement.getBoundingClientRect();
              __this.options = {
                left: option.left + obj.column.width / 2,
                top: option.top + 14,
              };

              let height = e.view.innerHeight;
              let leftWidth = e.clientX - e.srcElement.offsetWidth;
              let rightL = leftWidth + obj.column.width;
              let rightW = e.view.innerWidth - rightL;
              __this.shadeLeft = {
                width: leftWidth + "px",
                height: height + "px",
              };
              __this.shadeRight = {
                left: rightL + "px",
                height: height + "px",
                width: rightW + "px",
              };
            },
          },
        });
      };
    },
    genColMarkNames(column) {
      column.renderCell = (h, obj) => {
        let content = obj.row.MarkNameALL;
        return h("div", {
          domProps: {
            innerHTML: content,
          },
        });
      };
    },

    async onBindData(pageIndex = 1) {
      this.loading = true;
      let where = _.extend({
        FieldWhereString: JSON.stringify(this.searchFields),
        PageIndex: this.toInt(pageIndex, 1),
        PageSize: this.pageSize,
        onlySearchNoSalesPrice: this.searchFieldEx.onlySearchNoSalesPrice,
      });
      return await this.$post(
        this.$options.config.searchURL,
        where,
        (datas, logic) => {
          if (logic.code === 200) {
            this.pageIndex = pageIndex;
            this.itemSource = datas.ResultList;
            this.totalCount = datas.TotalCount;
            // console.log("执行到这里 111");
            //   this.resetTableHeight({
            //     dom: ".znl-m-all-stock .el-table",
            //     parentDom: ".znl-m-all-stock .znl-content",
            //     brother: [".znl-m-all-stock .isFieldsSearch",".znl-m-all-stock .footer"],
            //     surplus: 0,
            //  });
          }
        }
      ).finally(() => {
        this.loading = false;
        this.gridShow = true;
        this.$emit("page-loading", false);
        // 要放nextTick函数里面执行
        // this.$nextTick(() => {
        //   this.getModelSearch();
        // });
      });
    },
    // 搜索次数
    async getModelSearch() {
      let items = this.itemSource;
      if (items === null || items.length <= 0) {
        return;
      }
      // 1.映射  2.去重
      let models = _.uniq(_.map(items, (item) => item.Model));
      let modelDict = await this.$post(CONFIG.getModelSearchCntURL, {
        Models: models,
      });
      _.each(this.itemSource, (item) => {
        let cnt = modelDict[item.Model];
        if (cnt) {
          this.$set(item, "SearchCount", cnt);
        }
      });
    },

    ChangeValue(val) {
      this.$emit("searchfields-change", val);
    },
    btnSeach() {
      this.isShow = false;
      this.$refs.flexGrid.search();
      // this.$emit('update:searchModel', this.searchFields)
    },
    async onHeadSearch(params) {
      let rows = this.$refs.flexGrid.getChangedData();
      // 顶部搜索模块进来，重置搜索条件
      this.searchFields.IsWarning = false;
      this.searchFields.IsExtend = false;
      this.searchFields.IsSubscription = false;
      if (params && params.IsWarning) {
        this.searchFields.IsWarning = params.IsWarning;
      }
      if (rows.length > 0) {
        this.$confirm("当前数据已发生修改, 是否继续操作?", "提示", {
          confirmButtonText: "是",
          cancelButtonText: "否",
          type: "warning",
        }).then(() => {
          this.onSearchFields(params);
        });
      } else {
        this.onSearchFields(params);
      }
    },

    async onSearchFields(params) {
      this.searchFields = _.extend({}, this.searchFields, params);
      this.onBindData(1);
    },
    async onSearch(params) {
      if (params && !this.searchModel.activeState) {
        params.MarkNames = "";
        params.MarkValue = "";
      }
      this.searchFields = _.extend({}, this.searchFields, params);
      this.isShow = false;
      this.onBindData(1);
    },
    //页面内部搜索，给ERP客户端嵌套页面提供搜索功能
    async onInternalSearch() {
      //条件处理
      this.searchFields = _.extend({}, {}, this.internalSearchCondition);
      this.onBindData(1);
    },
    async onSaveSetting(columns, pageSize) {
      localStorage.removeItem("stockSummary");
      await this.$post(
        this.$options.config.saveConfigURL,
        this.flexGridSettingHandler(
          columns,
          pageSize,
          this.defaultConfig,
          this.parameterConfig
        )
      );
    },
    onResetParams(val) {
      localStorage.removeItem("stockSummary");
      return this.$post(CONFIG.resetParamConfigURL);
    },
    async onResetSetting() {
      localStorage.removeItem("stockSummary");
      await this.$post(this.$options.config.resetConfigURL);
    },
    // 右键点击表头设置列
    onSaveConfig(column) {
      localStorage.removeItem("stockSummary");
      return this.$post(
        this.$options.config.saveConfigURL,
        this.flexGridSettingHandler(
          [column],
          this.pageSize,
          this.defaultConfig,
          this.parameterConfig
        )
      );
    },
    async onRefresh() {
      await this.onBindData(this.pageIndex);
    },
    onSaveRows(rows) {
      if (rows.length > 0) {
        return this.$post(CONFIG.saveRowsURL, rows, (data, logic) => {
          if (logic.code === 200) {
            this.$message({ message: "保存成功", type: "success" });
            // this.onBindData(this.pageIndex)
            // this.$refs.flexGrid.$refs.actionFlexGrid.saveScueess()
            this.itemSource = JSON.parse(JSON.stringify(this.itemSource));
            this.$emit("on-saved", rows);
          } else if (logic.msg !== "") {
            this.$message({ message: logic.msg, type: "error" });
          } else {
            this.$message({ message: "保存失败", type: "error" });
          }
        });
      }
    },
    // 判断汇总库存权限
    hasRes(code) {
      return _.some(this.resourceList, (m) => m === code);
    },
    hasViewBuyPrice() {
      // 特殊权限，查看采购价
      let isnotSee = _.some(
        this.specialResource,
        (item) => item.Code === "ViewBuyPrice"
      );
      return isnotSee;
    },
    hasViewSalesPrice() {
      // 特殊权限，查看销售价
      let isnotSee = _.some(
        this.specialResource,
        (item) => item.Code === "ViewSalesPrice"
      );
      return isnotSee;
    },
    isShowMoreBtn(btns) {
      let isShow = false;
      _.each(btns, (btn) => {
        if (btn.isShow) {
          isShow = true;
          return true;
        }
      });
      return isShow;
    },

    onIsZero() {
      this.pageIndex = 1;
      this.onCleaSelectCount();
      this.$refs.flexGrid.search();
      // this.onSaveZeroStockWherePanram()
    },
    // 保存零库存的搜索条件参数
    onSaveZeroStockWherePanram() {
      let datas = _.find(this.ParamJosn, function (paramjosn) {
        if (paramjosn && paramjosn.ParamCode === "IsQueryZeroInventory") {
          return paramjosn;
        }
      });
      if (this.hasValue(datas)) {
        datas.ParamValue = this.searchFieldEx.isZero ? "1" : "0";
        this.$post(
          CONFIG.SavePageParamURL,
          { GCode: "StockSummary", ParamJson: [datas] },
          (data) => {}
        );
      }
    },

    reSearch() {
      // this.searchFields = _.extend(this.searchFields, {MarkNames: ''})
      this.pageIndex = 1;
      // this.$refs.flexGrid.search()
      this.onSearch();
      // this.onBindData(1)
    },
    extendTypeChange(stype) {
      let oldStype = this.extendType ? this.extendType.toString() : "";
      let __this = this;
      this.stype = stype.toString();
      if (stype !== this.extendType) {
        let param = {
          FilterType: 1,
          SType: stype,
          SUMGuids: [this.sumGuid],
        };
        this.$post(CONFIG.setStockExtendURL, param, (data, logic) => {
          // console.log(logic)
          if (logic.code === 200) {
            this.dialogMiniVisible = false;
            if (stype === -1) {
              this.$message({ message: "成功取消库存推广", type: "success" });
            } else {
              this.$message({
                message: "已成功推广库存 " + data + " 条",
                type: "success",
              });
            }
            this.onBindData(this.pageIndex);
          } else if (this.hasValue(logic.msg)) {
            this.$message({ message: logic.msg, type: "error" });
            __this.stype = oldStype;
          } else {
            __this.stype = oldStype;
            this.$message({ message: "设置推广库存失败", type: "error" });
          }
        });
      }
    },
    // 打标记
    onMarking() {
      this.checkedRows = this.selectedRows;
      this.selectedSType = 0;
      this.selectedSubtype = [];
      this.selectedMarkNames = "";
      if (!_.isUndefined(this.checkedRows) && this.checkedRows.length > 0) {
        this.sumGuids = _.map(this.checkedRows, (m) => m.SUMGuid);
        if (this.checkedRows.length === 1) {
          this.selectedSType = this.checkedRows[0].ExtendType;
          this.selectedSubtype = this.checkedRows[0].SubscriptionTypeList;
          this.selectedMarkNames = this.checkedRows[0].MarkNames;
        }
      } else {
        this.sumGuids = [];
      }
      this.searchCondition = {
        // 外部搜索条件
        IsWarning: this.searchFieldEx.IsWarning,
        IsExtend: this.searchFieldEx.IsExtend,
        IsSubscription: this.searchFieldEx.IsSubscription,

        FieldWhereString: JSON.stringify(this.searchFields), // 表头搜索
      };
      this.setMarkingVisible = true;
    },
    async onSumData(pageIndex = 1) {
      this.onCleaSelectCount();
      let where = _.extend(
        {
          FieldWhereString: JSON.stringify(this.searchFields),
          PageIndex: this.toInt(pageIndex, 1),
          PageSize: this.pageSize,
        },
        {
          // 外部搜索条件
          IsWarning: this.searchFieldEx.IsWarning,
          IsExtend: this.searchFieldEx.IsExtend,
          IsSubscription: this.searchFieldEx.IsSubscription,
          MatchOperation: this.matchOperation,
        }
      );
      let response = await this.$post(this.$options.config.searchSumURL, where);
      this.pageIndex = pageIndex;
      this.SumSearchList = response;
      this.isShow = true;
    },

    // 订阅设置
    async onSubSetting() {
      this.openBomaiFrom = "setting";
      let isLogBomAI = await this.LogBomAi();
      if (isLogBomAI) {
        this.openSubSetting();
      } else {
        // 打开扫码界面
        this.bomaiVisible = true;
      }
    },
    // 审批订阅客户
    async onApproval() {
      this.openBomaiFrom = "approval";
      let isLogBomAI = await this.LogBomAi();
      if (isLogBomAI) {
        this.openSubSetting();
      } else {
        // 打开扫码界面
        this.bomaiVisible = true;
      }
    },
    openSubSetting() {
      let bomAiToken = getCookie(BOMAICOOLIENAME, this.$store);
      if (this.hasValue(bomAiToken)) {
        let url = "?token=" + bomAiToken + "&hidetop=1&hidebottom=1";
        if (this.openBomaiFrom === "setting") {
          url = CONFIG.bomaiBasicSetupUrl + url;
        } else {
          url = CONFIG.bomaiApprovalURL + url;
        }
        window.open(url);
      } else {
        this.$message({
          message: "登录BOMAI平台失败!请联系系统管理员",
          type: "warning",
        });
      }
    },
    // 导出
    onExport() {
      let searchCondition = _.extend(
        {
          FieldWhereString: JSON.stringify(this.searchFields),
          PageIndex: this.toInt(this.pageIndex, 1),
          PageSize: this.pageSize,
        },
        {
          // 外部搜索条件
          IsWarning: this.searchFieldEx.IsWarning,
          IsExtend: this.searchFieldEx.IsExtend,
          IsSubscription: this.searchFieldEx.IsSubscription,
          MatchOperation: this.matchOperation,
        }
      );
      this.exportFormatVisible = true;
      let notExportColumns = "StockImage"; // 不进行导出的列
      this.setColumns = _.filter(this.columns, (col) => {
        return notExportColumns.indexOf(col.binding) === -1;
      });
      console.log("this.setColumns is ", this.setColumns);
      this.setDefaultConfigColumns = this.defaultConfig;
      this.setSaveConfigURL = this.$options.config.saveConfigURL;
      this.searchCondition = searchCondition;
    },
    // 添加物料
    onAdd() {
      this.editVisible = true;
      this.editTitle = "添加库存汇总";
    },
    // 修改物料
    onUpdate() {},
    // 设置为推广库存(弹出层)
    onOpenSetExtend() {
      // 获取选中的行
      this.checkedRows = this.selectedRows;

      this.searchCondition = {
        // 外部搜索条件
        IsWarning: this.searchFieldEx.IsWarning,
        IsExtend: this.searchFieldEx.IsExtend,
        IsSubscription: this.searchFieldEx.IsSubscription,

        FieldWhereString: JSON.stringify(this.searchFields), // 表头搜索
      };

      this.stockExtendVisible = true;
    },

    // 取消推广
    async onCancelExtend() {
      this.checkedRows = this.$refs.flexGrid.getSelectedRows();
      if (_.isUndefined(this.checkedRows) || this.checkedRows.length === 0) {
        this.$message({ message: "请先选择库存.", type: "warning" });
        return false;
      }
      let param = {
        SUMGuids: this.checkedRows.map((m) => m.SUMGuid),
        SType: -1,
      };
      this.$post(CONFIG.setStockExtendURL, param, (data, logic) => {
        // console.log(logic)
        if (logic.code === 200) {
          this.$message({
            message: "成功取消" + data + "条库存推广",
            type: "success",
          });
          this.onBindData(1);
        } else if (this.hasValue(logic.msg)) {
          this.$message({ message: logic.msg, type: "error" });
        } else {
          this.$message({ message: "取消库存推广失败", type: "error" });
        }
      });
    },

    // 取消订阅
    onCancelSubscript() {
      this.checkedRows = this.$refs.flexGrid.getSelectedRows();
      if (_.isUndefined(this.checkedRows) || this.checkedRows.length === 0) {
        this.$message({ message: "请先选择库存.", type: "warning" });
        return false;
      }

      let param = {
        SUMGuids: this.checkedRows.map((m) => m.SUMGuid),
        SType: -1,
      };

      this.$post(CONFIG.setSubscriptionURL, param, (data, logic) => {
        if (logic.code === 200) {
          this.$message({
            message: "已成功取消 " + data + " 条订阅库存",
            type: "success",
          });
          this.onBindData(1);
        } else if (this.hasValue(logic.msg)) {
          this.$message({ message: logic.msg, type: "error" });
        } else {
          this.$message({ message: "取消订阅库存失败", type: "error" });
        }
      });
    },

    // 设置为订阅库存（弹出层）
    onOpenSetSubscription() {
      // 获取选中的行
      this.checkedRows = this.selectedRows;

      this.searchCondition = {
        // 外部搜索条件
        IsWarning: this.searchFieldEx.IsWarning,
        IsExtend: this.searchFieldEx.IsExtend,
        IsSubscription: this.searchFieldEx.IsSubscription,

        FieldWhereString: JSON.stringify(this.searchFields), // 表头搜索
      };
      this.stockSubscrptVisible = true;
    },

    // 获取模块状态
    GetStatus(val) {
      this.Status = val !== 0;
      this.Status
        ? this.$refs.flexGrid.toggleTableShow(true)
        : this.$refs.flexGrid.toggleTableShow(false);
    },
    // 保存模块状态
    saveStatus(val) {
      let Status = val ? 1 : 0;
      if (Status !== this.defaultConfig.Status) {
        this.defaultConfig.Status = Status;
        let statusConfig = this.defaultConfig;
        statusConfig.ColumnConfigs = [];
        this.$post(this.$options.config.saveConfigURL, statusConfig);
      }
    },
    // 设置为订阅库存(悬停显示)
    onSetSubscription() {
      if (this.subscriptTypeRadio === -2) {
        this.$message({ message: "请至少选择一个订阅类型", type: "error" });
        return false;
      }
      let param = {
        FilterType: "1",
        SubscriptionType: this.subscriptTypeRadio,
        SUMGuids: [this.sumGuid],
      };
      let __this = this;
      this.$post(CONFIG.setSubscriptionURL, param, (data, logic) => {
        if (logic.code === 200) {
          let message = "已成功发布 " + data + " 条订阅库存";
          if (__this.subscriptTypeRadio === -1) {
            message = "已成功取消 " + data + " 条订阅库存";
          }
          this.$message({ message: message, type: "success" });
          this.onRefresh();
        } else if (this.hasValue(logic.msg)) {
          this.$message({ message: logic.msg, type: "error" });
        } else {
          this.$message({ message: "发布库存失败", type: "error" });
        }
        __this.dialogMiniSubVisible = false;
      });
    },

    // 标记设置成功后回调
    onStockMarkingConfirm(val) {
      this.$emit("refresh-marks");
      val && (this.setMarkingVisible = false);
      this.onRefresh();
      this.onCleaSelectCount();
    },

    toggleTableShow() {
      return this.$refs.flexGrid.toggleTableShow();
    },
    async onUploadQDW() {
      let rows = this.selectedRows;
      if (rows === null || rows === undefined || rows.length === 0) {
        this.$message({ message: "请先选择库存", type: "error" });
        return false;
      }
      let sumGuids = [];
      _.each(rows, (item) => {
        sumGuids.push(item.SUMGuid);
      });

      this.$post(
        CONFIG.UploadQDW,
        { SUMGuids: sumGuids, UploadQDW_byCondition: false },
        (data, logic) => {
          if (logic.code === 200) {
            this.$message({
              message: "已上传成功",
              type: "success",
            });
          } else if (this.hasValue(logic.msg)) {
            this.$message({ message: logic.msg, type: "error" });
          } else {
            this.$message({ message: "上传失败", type: "error" });
          }
        }
      );
    },

    // 根据查询条件上传到抢单王库存
    async onUploadQDW_byCondition(searchParam = null) {
      searchParam.UploadQDW_byCondition = true;

      this.$post(CONFIG.UploadQDW, searchParam, (data, logic) => {
        if (logic.code === 200) {
          this.$message({
            message: "已上传成功",
            type: "success",
          });
        } else if (this.hasValue(logic.msg)) {
          this.$message({ message: logic.msg, type: "error" });
        } else {
          this.$message({ message: "上传失败", type: "error" });
        }
      });
    },

    onUploadQDW_2click() {
      // 请求父窗口， 获取搜索条件参数，然后回调上传方法
      let vm = this;
      this.$emit("get-search-param", this.onUploadQDW_byCondition);
    },

    async onMarketSearch() {
      let rows = this.selectedRows;
      let marketRows = [];
      if (
        (this.searchFields.Model === null || this.searchFields.Model === "") &&
        (rows === null || rows.length === 0 || _.isUndefined(rows[0]))
      ) {
        return this.$message({
          message: "请输入搜索条件或勾选需要市场查货的汇总库存",
          type: "warning",
        });
      } else if (
        (this.searchFields.Model !== null || this.searchFields.Model !== "") &&
        (rows === null || rows.length === 0 || _.isUndefined(rows[0]))
      ) {
        const regex = /,|，/;
        let modeList = (
          this.searchFields.Model === null ? "" : this.searchFields.Model
        ).split(regex);
        _.each(modeList, (item) => {
          marketRows.push({
            Model: item,
          });
        });
      } else {
        marketRows = rows;
      }
      let request = [];
      _.each(rows, (item) => {
        request.push({
          Model: item.Model, // 型号，
          Brand: item.Brand, // 品牌，
          Pack: item.Packaging, // 封装，
          MakeYear: item.MakeYear, // 年份，
          Qty: item.InvQty, // 数量，
          Remark: item.Remark, // 需求说明
        });
      });
      this.$nextTick(function () {
        this.openMarketSearch({ Requests: request, ClearRequests: false });
      });
    },
    onCellClick(row, column, cell, event) {
      if (column.columnKey === "Model") {
        this.$emit("cell-click", row);
      }
    },
    onBatchSave() {
      let rows = this.$refs.flexGrid.getChangedData();
      if (rows === undefined || rows === null || rows.length <= 0) {
        return this.$message({ message: "数据没有发生变化", type: "warning" });
      } else {
        this.onSaveRows(rows);
      }
    },
    // 勾选选择框事件
    selectChange(rows) {
      this.selectedRows = rows;
    },
    // 清除选中行
    onCleaSelectCount() {
      this.selectedRows = [];
      this.$refs.flexGrid.clearSelection();
    },
    onCheckInfo() {
      if (this.selectedRows.length === 0) {
        return this.$message({ message: "至少选择一条数据", type: "warning" });
      } else {
        _.each(this.selectedRows, (item) => {
          item.CheckGUID = item.SUMGuid;
        });
        let order = { checkInfo: this.selectedRows };
        this.$store.commit("setParamOrder", order);
        this.$nextTick(function () {
          this.showStkCheckInfo = true;
        });
      }
    },
    onSeletedInfo(val) {
      this.selectedRows = val;
    },

    // 删除汇总库存
    onDelete() {
      this.checkedRows = this.$refs.flexGrid.getSelectedRows();
      if (_.isUndefined(this.checkedRows) || this.checkedRows.length === 0) {
        this.$message({ message: "请先选择库存.", type: "warning" });
        return false;
      }
      // 只能删除可用量为0，并且为设置推广、订阅的库存
      let msg = "";
      _.each(this.checkedRows, (row) => {
        if (row.InvQty !== 0) {
          msg = "型号" + row.Model + "的库存量不为0，不能删除";
        } else if (row.IsExtend) {
          msg = "型号" + row.Model + "已发布到推广库存，不能删除";
        } else if (row.IsSubscription) {
          msg = "型号" + row.Model + "已发布到订阅库存，不能删除";
        }
        if (msg !== "") {
          return true;
        }
      });
      console.log(".checkedRows is ", this.checkedRows);
      let sumGuids = _.map(this.checkedRows, (x) => x.SUMGuid);
      console.log("sumGuids is ", sumGuids);

      //删除汇总库存
      this.$post(CONFIG.deleteUrl, sumGuids, (data, loggic) => {
        if (data > 0) {
          this.$message({ type: "success", message: "删除成功！" });
          this.onBindData();
        } else {
          this.$message({ type: "error", message: "删除失败！" });
        }
      });
    },
    //汇总库存
    onStockSummary() {
      this.isSummary = true;
      this.$post(CONFIG.StockSummaryFromStockUrl, {}, (data, loggic) => {
        this.isSummary = false;
        if (data > 0) {
          this.$message({ type: "success", message: "汇总成功！" });
        } else {
          this.$message({ type: "error", message: "汇总失败！" });
        }
      });
    },
    // // 上传图片
    // onUploadImage () {
    //   this.imageUploadVisible = true
    // },
    // 关闭图片走马灯时执行
    // onShowImageClose (deletedImgGuids) {
    //   this.imageBoxVisible = false
    //   if (deletedImgGuids && deletedImgGuids.length > 0) {
    //     _.each(this.itemSource, item => {
    //       _.each(deletedImgGuids, imgGuid => {
    //         let index = _.findIndex(item.StockImages, m => m.ImgGuid === imgGuid)
    //         if (index > -1) {
    //           item.StockImages.splice(index, 1)
    //         }
    //       })
    //     })
    //   }
    // }
    onShowSetSalesPrice() {
      this.isShowSetSalesPrice = true;
    },
    onSalesPriceClose(refresh) {
      this.isShowSetSalesPrice = false;
    },
  },
  mounted() {
    // console.log("汇总库存");
  },
};
</script>

<style lang="scss">
// .znl-m-all-stock {
//   td {
//     padding: 0 3px !important;
//     height: 26px !important;
//     box-sizing: border-box;
//   }
//   th {
//     padding: 0 !important;
//     height: 24px !important;
//     box-sizing: border-box;
//   }
// }

.table-columnbtn-enable {
  color: #ff0000 !important;

  .el-button:hover {
    color: #ff0000;
  }
  line-height: 24px;
}
.table-columnbtn-disabled {
  color: #cccccc;

  .el-button:hover {
    color: #000000;
  }
}
.type4 {
  color: red;
  .el-radio__input.is-checked .el-radio__inner {
    color: red;
    border-color: red;
    background: red;
  }
  .el-radio__input.is-checked + .el-radio__label {
    color: red;
  }
}
.type6 {
  color: #ff9933;
  .el-radio__input.is-checked .el-radio__inner {
    color: #ff9933;
    border-color: #ff9933;
    background: #ff9933;
  }
  .el-radio__input.is-checked + .el-radio__label {
    color: #ff9933;
  }
}
.type9 {
  color: #515151;
  .el-radio__input.is-checked .el-radio__inner {
    color: #515151;
    border-color: #515151;
    background: #515151;
  }
  .el-radio__input.is-checked + .el-radio__label {
    color: #515151;
  }
}
.mini-dialog {
  .mleft {
    background: #fff;
    position: inherit;
    width: 100%;
    height: 100%;
    padding: 5px;
    div {
      padding: 5px;
    }
    .el-radio__label {
      font-size: 12px;
    }
    .warn-left {
      left: 70px;
      position: absolute;
      margin-top: 3px;
    }
  }
  .myshade {
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
  }
}
.sub-checkbox-list {
  position: absolute;
  // margin-left: 50px;
  padding-top: 0 !important;
  margin-left: 44px;
  .el-checkbox {
    margin-right: 10px;
    display: inline;
  }
}
.hover-set {
  color: #0000ff;
}
.price-color {
  color: #e20f0f;
}
.qty-color {
  color: blue;
}
.color-ccc {
  color: #ccc;
}
.no-underline {
  text-decoration: none;
}
.color-glod {
  color: #f5a623;
  .el-radio__input.is-checked .el-radio__inner {
    color: #f5a623;
    border-color: #f5a623;
    background: #f5a623;
  }
  .el-radio__input.is-checked + .el-radio__label {
    color: #f5a623;
  }
}
.color-silver {
  color: #8f9eb3;
  .el-radio__input.is-checked .el-radio__inner {
    color: #8f9eb3;
    border-color: #8f9eb3;
    background: #8f9eb3;
  }
  .el-radio__input.is-checked + .el-radio__label {
    color: #8f9eb3;
  }
}
.color-copper {
  color: #997d7d;
  .el-radio__input.is-checked .el-radio__inner {
    color: #997d7d;
    border-color: #997d7d;
    background: #997d7d;
  }
  .el-radio__input.is-checked + .el-radio__label {
    color: #997d7d;
  }
}
.color-green {
  color: #2ebb2e;
}
.znl-gridModuleHeader .heading-slot .box_summary {
  // padding-left: 160px;
  .el-checkbox {
    margin-right: 5px !important;
  }
  .znl-float-list_erp {
    .znl-float-wrapper {
      width: 70px !important;
    }
  }
}
.stsPurchaseIn-amount {
  color: rgb(255, 0, 0);
}
.show-hand {
  cursor: pointer;
}
.share-icon {
  color: #00cc00;
  font-size: 14px;
  padding-left: 1px;
}
.stock-summary-field-spec > span {
  font-weight: bolder;
}
</style>

